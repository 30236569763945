import React from 'react';

import { Grid, Typography, Card, CardContent, styled } from '@mui/material';
import { FileDownloadRounded, HourglassTopRounded, ErrorOutline } from '@mui/icons-material';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import { downloadDocumentationFile } from '../../api';

const CardWrapper = styled(Card)(
  ({ theme }) => `
  margin-bottom: 3px;
            &.error {
              border: 1px solid ${theme.palette.error.main};
            }
            .hourglass {
                color: ${theme.palette.primary.light};
                font-size: 50px;
            }
            .download-arrow {
              font-size: 50px;
            }
            .cardTop:hover {
              background-color: ${theme.palette.action.hover};
              cursor: pointer;
            }
            .file-name {
              background-color: ${theme.palette.background.paper};
            }
          }
  `
);

function FileDownloadCard(props) {
  const [downloadInProgress, setDownloadInProgress] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const handleDownload = async () => {
    try {
      setDownloadInProgress(true);
      setHasError(false);
      const { url, filename } = await downloadDocumentationFile(props.file);
      const result = await fetch(url);
      const data = await result.blob();

      fileDownload(data, filename);

      setDownloadInProgress(false);
    } catch (error) {
      // do something with the error
      console.log(error);
      console.log('filename = ' + props.file);
      setDownloadInProgress(false);
      setHasError(true);
    }
  };

  const downloadButton = <Typography variant="text" color="primary" sx={{ fontWeight: 500, letterSpacing: '0.46px', fontSize: '13px', mb: '8.5px', display: 'inline-flex' }}>DOWNLOAD {props.fileType}</Typography>;

  const loadingMessage = <Typography sx={{ mt: '10px', display: 'inline-flex' }} variant="body2" color="text.secondary">Retrieving file, please wait...</Typography>;

  return (
    <div className={props.variation === 'card' ? 'show' : 'hide'} >
      <CardWrapper className={hasError === false ? '' : 'error'}>
        <CardContent sx={{ padding: '0' }}>
          <Grid onClick={handleDownload} className="cardTop" container sx={{ textAlign: 'center' }}>
            <Grid item xs={12} sx={{ pt: 2 }}>
              {downloadInProgress === false ? <FileDownloadRounded className="download-arrow" color="primary" /> : <HourglassTopRounded className="hourglass" color="primary" />}
            </Grid>
            <Grid item xs={12} sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
              {downloadInProgress === false ? downloadButton : loadingMessage}
            </Grid>
            <Grid className="file-name" item xs={12} sx={{ textAlign: 'left', padding: '15px 20px 0px 20px', minHeight: '60px', display: 'flex', alignItems: 'center' }}>
              <div>
                <Typography sx={{ width: '100%' }} variant="body2">{props.fileName}</Typography>
                {props.fileType === 'CSV' ? <Typography variant="caption" color="text.secondary">CSV Files must be pipe delimited.</Typography> : ''}
              </div>

            </Grid>
          </Grid>
        </CardContent>
      </CardWrapper>
      {hasError === false ? '' : <span><ErrorOutline sx={{ color: 'error.main', float: 'left', ml: 3, fontSize: '20px', mt: '4px', mr: '4px' }} /><Typography variant="caption"> Error retrieving file, try again.</Typography></span>}
    </div>
  );
}

FileDownloadCard.propTypes = {
  variation: PropTypes.string,
  fileType: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};

export default FileDownloadCard;