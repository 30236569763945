import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Button, styled, Box } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButtonWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: 0px !important;
        padding-right: 0px !important;
        .logout-button {
          text-transform: capitalize;
          font-size: 17px;
          font-weight: 400;
          color: ${theme.palette.text.primary};
        }
        .logout-icon {
          color: ${theme.palette.text.secondary};
          font-size: 27px;
          margin-right: 8px;
        }
`
);

function LogoutButton() {
  const { oktaAuth } = useOktaAuth();
  const handleLogout = async () => oktaAuth.signOut();

  return (
    <LogoutButtonWrapper>
      <Button className="logout-button" variant="text" color="inherit" onClick={handleLogout}> <LogoutIcon className="logout-icon" />Sign Out</Button>
    </LogoutButtonWrapper>

  );
}

LogoutButton.propTypes = {};

export default LogoutButton