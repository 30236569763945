import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { ArrowBack, ContactsRounded } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Paper, Typography, Alert } from '@mui/material';

import { createOktaUser } from 'src/api';
import NameField from 'src/components/FormFields/firstName';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import UserRoleField from 'src/components/FormFields/userRole';

function Loading() {
  return (
    <Grid container spacing={3} sx={{ p: 3, textAlign: 'center' }}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <LoadingIcon contained={true} height="187px" marginTop="10%" />
      </Grid>
    </Grid>
  );
}

function CustomerAccountAddUser({ backToUsers, carrierConfig, reload, users, showAddUserSuccess }) {

  yup.addMethod(yup.string, 'unique', function (message) {
    return yup.string().test('unique', message, function (email) {
      let obj = users.find(o => o.email === email);
      return !obj;
    });
  });

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required('Field is required')
      .max(50, 'First Name must be 50 characters or less'),
    lastName: yup
      .string()
      .max(50, 'Last Name must be 50 characters or less')
      .required('Field is required'),
    emailAddress: yup
      .string()
      .min(5, 'Email must be at least 5 characters')
      .max(100, 'Email must be be 100 characters or less')
      .email('Must enter a valid email address')
      .required('Field is required')
      .unique('Email entered already exists. Try a different email.'),
    lens_roles: yup
      .string()
      .required('Field is required'),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const clearError = () => {
    setHasError(false);
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      lens_roles: 'collaborator'
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        setIsLoading(true);
        const payload = {
          profile: {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            email: formik.values.emailAddress,
            lens_roles: [formik.values.lens_roles]
          }
        }

        const result = await createOktaUser(carrierConfig?.id, payload);

        setIsLoading(false);

        if (result?.firstName && result?.lastName) {
          reload();
          backToUsers();
          showAddUserSuccess(result?.firstName, result?.lastName);
        }
        else if (result?.message === 'Duplicate user') {
          formik.setErrors({emailAddress: 'Email entered already exists. Try a different email.'});
        }
        else if (result?.message === 'Email domain does not match claim key') {
          formik.setErrors({emailAddress: 'Email domain does not match claimkey. Contact support to resolve.'})
        }
        else {
          window.scrollTo(0, 0);
          setHasError(true);
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  });

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: 6, fontWeight: 700, mt: { xs: 1, md: 2 } }}>
            ADD NEW USER
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <Button onClick={backToUsers} variant="text" color="primary"><ArrowBack sx={{ mr: 3 }} />Back <Box sx={{ display: { xs: 'none', sm: 'block' } }}>&nbsp;to All Users</Box></Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ mb: 3 }}>
            {isLoading ? <Loading /> :
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={3} sx={{ p: 3 }}>
                    <Grid item xs={12} sx={{ mt: -3 }}>
                      {hasError ?
                        <Alert sx={{ mx: 3 }} severity="error" onClick={clearError}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
                        : ''}
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Avatar variant="rounded" sx={{ margin: '0 auto 15px', backgroundColor: '#bdbdbd', width: '60px', height: '60px', borderRadius: '6px' }}>
                        <ContactsRounded sx={{ color: 'background.paper', fontSize: '30px' }} />
                      </Avatar>
                      <Typography variant="h5">
                        {(formik.values.firstName || formik.values.lastName) ? formik.values.firstName + ' ' + formik.values.lastName : 'First Name Last Name'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ p: 3 }}>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      <NameField name="firstName" label="First Name" formik={formik} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                      <NameField name="lastName" label="Last Name" formik={formik} />
                    </Grid>
                    <Grid onClick={clearError} item xs={12} sx={{ textAlign: 'center', mt: -2 }}>
                      <NameField name="emailAddress" label="Email Address" formik={formik} />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'left', mt: -2 }}>
                      <UserRoleField name="lens_roles" formik={formik} field="lens_roles" label={"Role"}  fieldLabel={'Assign Role'} width="100%"/>
                    </Grid>
                  </Grid>

                  <hr />

                  <Grid container spacing={3} sx={{ p: 3 }}>
                    <Grid item xs={6}>
                      <Button className="customButton" onClick={backToUsers} variant="outlined" color="primary">Cancel</Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      <Button type="submit" variant="contained" color="primary" disabled={!formik.isValid} >
                        Add User
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </FormikProvider>
            }
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

CustomerAccountAddUser.propTypes = {
  backToUsers: PropTypes.func,
  carrierConfig: PropTypes.object, 
  reload: PropTypes.func, 
  users: PropTypes.array, 
  showAddUserSuccess: PropTypes.func
}

export default CustomerAccountAddUser;