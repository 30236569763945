import { useOktaAuth } from '@okta/okta-react';
import { useQuery as _useQuery} from 'react-query';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

export const useQuery = function(queryKey, queryfn) {
  const handleLogin = async function () {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    await oktaAuth.signInWithRedirect();
  }

  const {oktaAuth, authState } = useOktaAuth();
  const {isError, error, data, isLoading} = _useQuery(queryKey, queryfn);
  const navigate = useNavigate();

  let _isError = isError;
  let _error = error;

  if(data && !isError) {
    const {statusCode, status} = data;

    const _statusCode = statusCode || status;
    
    if(!isNaN(_statusCode)) {
      _isError = (_statusCode > 201 && _statusCode !== 401);
      _error = data;

      if(_statusCode === 401) {
        if(!authState?.isAuthenticated){
          return {isError: false, isLoading: true};
        } else{
          navigate('/401');
        }

        handleLogin().catch(err => {
          _isError = true;
          _error = err;
        })
      }
    }
  }

  return {isError: _isError, error: _error, data, isLoading};
};