import React from 'react';
import { styled, Box, CircularProgress, Typography } from '@mui/material'
import PropTypes from 'prop-types';

const LoadingWrapperContained = styled(Box)(
  ({theme}) => `
    background-color:${theme.colors.alpha.white[70]};
    z-index: 9996;

    .spinner {
      position: relative;
      display: block;
      z-index: 9997;
      margin: 0 auto 0;
      color: ${theme.palette.primary.main};
    }

    @media screen and (max-width: 1279px) {
      .loading-inner-wrapper {
        padding-left: 0px;
      }
  
    }
  `
);

const LoadingWrapper = styled(Box)(
  ({theme}) => `
    background-color:${theme.colors.alpha.white[70]};
    z-index: 9996;
    position: fixed; 
    width: 100VW;
    height: 100VH;
    top: 0px;
    left: 0px;

    .loading-inner-wrapper {
      padding-left: 267px;
    }

    .spinner {
      position: relative;
      display: block;
      z-index: 9997;
      margin: 45vh auto 0;
      color: ${theme.palette.primary.main};
    }

    @media screen and (max-width: 1279px) {
      .loading-inner-wrapper {
        padding-left: 0px;
      }
  
    }
  `
);

const Icon = () => {
  return (
    <div className="loading-inner-wrapper">
      <CircularProgress size="10vh" className="spinner" />
      <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>Stay put &mdash; the request might take a few seconds...</Typography>
    </div>
  )
}

const LoadingIcon = ({contained = false, marginTop = '12%', height = 'auto'  }) => {

  return (
    <>
      {contained ?
        <LoadingWrapperContained sx={{marginTop: marginTop }} height={height}>
          <Icon />
        </LoadingWrapperContained>
        :
        <LoadingWrapper>
          <Icon />
        </LoadingWrapper>
      }
    </>
  );
};

LoadingIcon.propTypes = {
  contained: PropTypes.bool,
  marginTop: PropTypes.string,
  height: PropTypes.string
}

export default LoadingIcon;
