import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from "@mui/material/CssBaseline";
import { Grid, Box, styled, ThemeProvider } from '@mui/material';
import '../../App.css';

import AppBar from '../AppBar';
import Footer from '../Footer';

import { EvadataTheme } from 'src/theme/schemes/EvadataTheme';
import { GreenFieldsTheme } from 'src/theme/schemes/GreenFieldsTheme';
import ThemeSettings from 'src/components/ThemeSettings';

const MainContentWrapper = styled(Box)(
  () => `
    margin-bottom: 20px;
    text-align: center;
    min-height: calc(100vh - 190px);

    @media screen and (max-width: 1279px) {
      margin-left: 0px;
    }
`
);

function Layout(props) {
  const curThemeName = localStorage.getItem('appTheme') || 'EvadataTheme';

  useEffect(() => {
    localStorage.setItem('appTheme', data);
  });

  const [data, setData] = useState(curThemeName);
  
  const childToParent = (childData) => {
    setData(childData);
  }

  return (
    <Box>
      <Grid className='primary-layout' container spacing={2} justifyContent="center" textAlign="left">
        <ThemeProvider theme={data === 'EvadataTheme' ? EvadataTheme : GreenFieldsTheme}>
          <CssBaseline />
          <AppBar />
          <Grid item sm={12} sx={{ mt: '67px' }}>
            <MainContentWrapper sx={{ px: 3, textAlign: 'left' }}>
              {props.children}
            </MainContentWrapper>
            <Footer />
          </Grid>
          <ThemeSettings childToParent={childToParent}/>
        </ThemeProvider>
      </Grid>
    </Box>

  );
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout;