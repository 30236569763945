import React from 'react';

import { Link } from 'react-router-dom';

import { styled, Box, Typography, Tooltip } from '@mui/material';
import { ReactComponent as EvadataLogo } from 'src/images/EvadataLogo.svg'

const LogoAreaWrapper = styled(Box)(
  ({ theme }) => `
            .logo {
                display: block;
                float: left;
                margin-top: 15px;
            }
            .logo svg {
                filter: invert(${theme.colors.logo.inverse});
            }
            .lens-text {
                text-align: left;
                font-weight: bold; 
                color: ${theme.colors.logo.productText}; 
                display: inline-block;
                margin-left: 7px;
                margin-top: 15px;
            }
  `
);

function LogoArea() {

  return (
    <LogoAreaWrapper>
      <Link to="/">
        <Tooltip arrow title="Evadata Admin">
          <Typography className="logo">
            <EvadataLogo />
          </Typography>
        </Tooltip>
      </Link>
    </LogoAreaWrapper>


  );
}

export default LogoArea;