import React from 'react';
import { Skeleton, Box } from '@mui/material';

const SkeletonRow = () => (
  <Box sx={{}}>
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "120px", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "120px", float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "120px", float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "195px", float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "100px", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "100px", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "130px", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "80px", float: 'left' }} />
  </Box>
);

function SkeletonRows() {

  return (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  )
}

export default SkeletonRows;