import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { ReactComponent as EvadataLogo } from 'src/images/EvadataLogo404.svg'
import Graphic404 from 'src/images/404PageSearchGraphic.png';
import { ArrowBackRounded } from '@mui/icons-material';

function NotFound404() {

  return (
    <Grid container>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '100px' }}>
        <EvadataLogo />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
        <img src={Graphic404} alt="Page error 404" />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
        <Typography variant="h2">The page can't be found!</Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h5" color="black" sx={{ maxWidth: '700px', margin: '0 auto' }}>Sorry but the page you are looking for does not exist, has been moved, the name changed or is temporarily unavailable.</Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '50px' }}>
        <Button href="/" variant="contained" color="primary" startIcon={<ArrowBackRounded />}>Back Home</Button>
      </Grid>
    </Grid>
  );
}


NotFound404.propTypes = {};

export default NotFound404;