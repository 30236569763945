import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

function NameField({ formik, label, name }) {
  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      className="firstName"
      value={formik?.values?.[name]}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={formik.touched?.[name] && (Boolean(formik.errors?.[name]))}
      helperText={formik.touched?.[name] && (formik.errors?.[name]) ? formik.errors?.[name] : ''}
      sx={{ mb: 3}}
    />
  )
}

NameField.propTypes = {
  formik: PropTypes.object, 
  label: PropTypes.string, 
  name: PropTypes.string
};

export default NameField;
