const internals = {};

export const loadOrgWellKnown = async function(oktaAuth) {
  const endpoint = internals.getWellKnownEndpoint(oktaAuth);
  const url = new URL(endpoint);
  
  url.search = new URLSearchParams({
    client_id: process.env.REACT_APP_CLIENT_ID
  });

  const result = await fetch(url);
  const payload = await result.json();
  const item = {
    expiresAt: Math.floor(Date.now()/1000) + 86400,
    response: payload
  };

  oktaAuth.storageManager.getHttpCache().setItem(endpoint, item);

  return payload;
};

export const getOrgEndpoints = function(oktaAuth) {
  const wellKnownEndpoint = internals.getWellKnownEndpoint(oktaAuth);
  const _openIdConfig = oktaAuth.storageManager.getHttpCache().getItem(wellKnownEndpoint);

  if(_openIdConfig) {
    const {response: {authorization_endpoint, revocation_endpoint, token_endpoint}} = _openIdConfig;

    return {authorization_endpoint, revocation_endpoint, token_endpoint};
  }
};

export const getOrgAccessToken = function(oktaAuth) {
  const storageProvider = oktaAuth.tokenManager.getStorage();
  const token = storageProvider.getItem('oktaApiToken');

  if(token) {
    return token.apiAccessToken
  }
};

export const revokeOrgAccessToken = async function (oktaAuth) {
  const {revocation_endpoint} = getOrgEndpoints(oktaAuth);
  const orgAccessToken = getOrgAccessToken(oktaAuth);

  return fetch(revocation_endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${window.btoa(process.env.REACT_APP_CLIENT_ID)}` // See: https://developer.okta.com/docs/reference/api/oidc/#client-authentication-methods
    },
    body: new URLSearchParams({
      token_type_hint: 'accessToken',
      token: orgAccessToken
    })
  });
};

export const exchangeForAccessToken = async function(oktaAuth, code) {
  const transactionStorage = oktaAuth.storageManager.getTransactionStorage();
  const {token_endpoint} = getOrgEndpoints(oktaAuth);
  const codeVerifier = transactionStorage.getItem('oktaOrgCodeVerifier');
  const origin = window.location.origin;

  const result = await fetch(token_endpoint, {
    method: 'POST',
    body: new URLSearchParams({
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: `${origin}/login/okta-callback`,
      grant_type: 'authorization_code',
      code_verifier: codeVerifier,
      code
    })
  });

  return result.json();
};

internals.getWellKnownEndpoint = function(oktaAuth) {
  const issuerOrigin = oktaAuth.getIssuerOrigin();
  return `${issuerOrigin}/.well-known/openid-configuration`;
};
