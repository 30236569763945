import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Typography, Button, Alert, Box } from '@mui/material';
import { EditRounded } from '@mui/icons-material';

import LoadingIcon from 'src/components/Loading/loadingIcon';

function Loading() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ minHeight: '300px' }}>
          <LoadingIcon contained={true}/>
        </Box>
      </Grid>
    </Grid>
  );
}

function WidgetCard({ title, children, editingForm, loading, editing, setEditing, generalizedError, setGeneralizedError }) {
  const cancelEdit = () => {
    setEditing(false);
    setGeneralizedError(false);
  }

  return (
    <Paper>
      {loading ? <Loading /> :
        <>
          <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1 }}>
            <Grid item xs={8}>
              <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              {editing ?
                <Button onClick={cancelEdit} sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button>
                :
                <Button onClick={() => {setEditing(true)}} startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button>
              }            
            </Grid>
          </Grid>
          <hr/>
          <Grid container spacing={3} sx={{ p: 4, pt: 3 }}>
            <Grid item xs={12}>
              {editing ?
                <>
                  <Alert sx={{mb: 2}} className={generalizedError ? 'show' : 'hide'} severity="error">We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
                  {editingForm}
                </>
                :
                <>
                  {children}
                </>
              }
            </Grid>
          </Grid>
          {editing &&
            <>
              <hr/>
              <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                <Button type="submit" variant="contained">
                  Save Changes
                </Button>
              </Box>
            </>
          }
        </>
      }
    </Paper>
  );
}

WidgetCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  editingForm: PropTypes.object,
  loading: PropTypes.bool,
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
  generalizedError: PropTypes.bool,
  setGeneralizedError: PropTypes.func
}

export default WidgetCard;