import { Field } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FormControlLabel, FormControl, Checkbox, FormHelperText, Typography } from '@mui/material';

import { sourceDisplayMap } from 'src/utilities/mapSourceDisplay';


function SelectSources({ formik }) {
  const validationTriggered = Boolean(formik.touched.sources && formik.errors.sources);

  return (
    <FormControl sx={{ pb: 2 }} component="fieldset" >
      <FormHelperText error={validationTriggered}>
        <Typography variant="body2" ml={-2}>Minimum of one source required.{validationTriggered && '*'}</Typography>
      </FormHelperText>
      {_.chain(sourceDisplayMap)
        .toPairs()
        .sortBy((val) => val[1])
        .map((source) => 
          <Field 
            sx={{ pl: 1 }}
            as={FormControlLabel}
            key={source[0]}
            name="sources"
            value={source[0]}
            checked={formik?.values?.sources.includes(source[0])}
            control={<Checkbox onChange={(e) => {formik.setFieldTouched('sources'); formik.handleChange(e);}} />}
            label={source[1]} />)
        .value()}
      <FormHelperText sx={{ visibility: validationTriggered ? 'visible' : 'hidden' }} error={true}>
        <Typography fontSize="13px" ml={-2}>*At least one source selection is required.</Typography>
      </FormHelperText>
    </FormControl>
  );
}

SelectSources.propTypes = {
  formik: PropTypes.object.isRequired
};

export default SelectSources;