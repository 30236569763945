import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material'
import { InfoRounded } from '@mui/icons-material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, useArrow, placement, maxWidth, ...props }) => (
  <Tooltip arrow={useArrow} placement={placement} {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

function TooltipInline({ text, tip, variant = 'body2', color = 'primary', display = '-webkit-inline-box', useArrow = false, placement = 'bottom', marginBottom = 0 }) {

  return (
    <Box sx={{ display: display, alignItems: 'center', flexWrap: 'wrap', marginBottom: marginBottom }}>
      <Typography variant={variant} color={color}>{text}
      </Typography>
      <CustomWidthTooltip arrow={useArrow} placement={placement} title={tip}>
        <InfoRounded color="primary"></InfoRounded>
      </CustomWidthTooltip>
    </Box>
  );
}

TooltipInline.propTypes = {
  text: PropTypes.string,
  tip: PropTypes.string, 
  variant: PropTypes.string, 
  color: PropTypes.string, 
  display: PropTypes.string, 
  useArrow: PropTypes.bool, 
  placement: PropTypes.string,
  marginBottom: PropTypes.number
};

export default TooltipInline;
