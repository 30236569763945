import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Button, Grid, IconButton, Tooltip, Typography, styled, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';

const ActionsWrapper = styled(Box)(
  ({ theme }) => `
  .calendar-btn-group {
    border: 1px solid ${theme.palette.text.disabled}
  }
  .inactive-view {
    color: ${theme.palette.text.disabled}
  }
`);

const Actions = ({ date, onNext, onPrevious, onToday }) => {
  const { t } = useTranslation();

  return (
    <ActionsWrapper>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
                <Grid
          item
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: 'bold', mr: 10 }}>
            {format(date, 'yyyy')}
          </Typography>
          </Grid>
        <Grid item>
          <Tooltip arrow placement="top" title={t('Previous')}>
            <IconButton color="primary" onClick={onPrevious}>
              <ArrowBackTwoToneIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              mx: 1
            }}
            onClick={onToday}
          >
            Today
          </Button>
          <Tooltip arrow placement="top" title={t('Next')}>
            <IconButton color="primary" onClick={onNext}>
              <ArrowForwardTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </ActionsWrapper>
  );
};

Actions.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onToday: PropTypes.func
};

Actions.defaultProps = {
  onNext: () => { },
  onPrevious: () => { },
  onToday: () => { }
};

export default Actions;
