import React, { useEffect } from 'react';

import { useAppContext } from '../../AppContext'
import LoadingIcon from './loadingIcon';
import { useQuery } from 'src/hooks';
import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';


const InitialLoader = () => {
  const { setOktaUser } = useAppContext();
  const { oktaAuth, authState } = useOktaAuth();

  const user = useQuery('userInfo', (() => oktaAuth.getUser()));

  useEffect(() => {
    if(!authState?.isAuthenticated) {
      return;
    }

    if(!user.isLoading) {
      setOktaUser(user.data);
    }


  }, [user, setOktaUser, authState]);

  
  return (
    <>
      { user.isLoading ? <LoadingIcon />: <Outlet/> }
    </>
  );
};

export default InitialLoader;