import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid, Paper, Typography, Alert } from '@mui/material';
import TableWithActionButton from 'src/components/TableWithActionButton';
import { getCarrierConfiguration, getOktaUsers } from 'src/api';
import SideNavigation from 'src/components/SideNavigation';
import PageTitle from 'src/components/PageTitle';
import getUserColumns from './columns';
import CustomerAccountEditUser from './Customer Account Edit User';
import SkeletonRowUsers from './SkeletonRowUsers';
import CustomerAccountAddUser from './Customer Account Add User';

const drawerWidth = 400;

function CustomerAccountUsers() {
  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('id');
  const companyName = searchParams.get('name');
  const [users, setUsers] = useState([]);
  const [carrierConfig, setCarrierConfig] = useState([]);
  const [managingUsers, setManagingUsers] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isDataTableLoading, setIsDataTableLoading] = useState(true);
  const [stateRefreshCounter, setStateRefreshCounter] = useState(0);
  const [addUserSuccess, setAddUserSuccess] = useState(false);
  const [addUserFirstName, setAddUserFirstName] = useState('');
  const [addUserLastName, setAddUserLastName] = useState('');
  const [hideUserTable,setHideUserTable] = useState(false);
  
  function callManageUsers(row) {
    setCurrentUser(row);
    setManagingUsers(true);
  }

  function backToUsers() {
    setManagingUsers(false);
    setCreatingUser(false);
    reload();
  }

  async function showAddUserSuccess(firstName, lastName) {
    setAddUserFirstName(firstName);
    setAddUserLastName(lastName);
    setAddUserSuccess(true);
    setTimeout(() => setAddUserSuccess(false), 3000);
  }

  useEffect(() => {
    const getUsers = async function () {
      
      const _users = await getOktaUsers(carrierId);
      if (_users.statusCode === 400 || _users.statusCode === 500 || _users.length === 0){
        setHideUserTable(true)
      } else {
        setUsers(_users);
        setHideUserTable(false)
      }
    };

    const getConfig = async function () {
      const _config = await getCarrierConfiguration(carrierId);
      setCarrierConfig(_config);
    };

    if (carrierId) {
      Promise.all([
        getConfig(),
        getUsers()
      ]).finally(() => setIsDataTableLoading(false))
    };
  }, [carrierId, stateRefreshCounter]);

  function reload() {
    const counter = (stateRefreshCounter + 1);
    setStateRefreshCounter(counter);
  }

  return (
    <>
      <PageTitle title={companyName} />
      <Box sx={{ display: 'flex' }}>
        <SideNavigation drawerWidth={drawerWidth} selected="users" />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
        >
          {(!managingUsers && !creatingUser) &&
            <>
              <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 3 }, fontWeight: 700 }}>
                USERS
              </Typography>
              <Grid container spacing={3}>
                <Alert severity="success" className={addUserSuccess ? 'show' : 'hide'} sx={{ mx: 2, mt: 2 }}>{`${addUserFirstName} ${addUserLastName} created successfully!`}</Alert>
                <Grid item xs={12}>
                  <Paper sx={{ mb: 2, mt: 1 }}>
                    <Grid container spacing={2} sx={{ pl: 2, pt: 0 }}>
                      <TableWithActionButton
                        skeleton={<SkeletonRowUsers />}
                        customActionButton={true}
                        customButtonText="Create User"
                        customButtonAction={() => setCreatingUser(true)}
                        hideTable={hideUserTable}
                        message={'There are no users to display.'}
                        isDataTableLoading={isDataTableLoading}
                        initialState={{sorting: {sortModel: [{ field: 'dateCreated', sort: 'desc' }],},}}
                        rows={users}
                        columns={getUserColumns(callManageUsers)}
                        rowHeight={60}
                        tableHeight={490}
                        title="Manage Users"
                      />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </>
          }
          {managingUsers && <CustomerAccountEditUser currentUser={currentUser} backToUsers={backToUsers} users={users} carrierConfig={carrierConfig} reload={reload} />}

          {creatingUser && <CustomerAccountAddUser backToUsers={backToUsers} users={users} carrierConfig={carrierConfig} reload={reload} showAddUserSuccess={showAddUserSuccess}/>}
        </Box>
      </Box>
    </>
  );
}

export default CustomerAccountUsers;