import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Typography, Stepper, Step, StepLabel, StepContent, Chip, Checkbox, Alert, styled, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import SideNavigation from 'src/components/SideNavigation';
import PageTitle from 'src/components/PageTitle';
import { getCarrierConfiguration, getMatchFileConfigurations, getResourceProvisioningStatus, provisionResources, updateSourcesCarrierConfiguration, updateStatusCarrierConfiguration, deleteAllConfigurations } from 'src/api';
import { Link, useSearchParams } from 'react-router-dom';
import TooltipInline from 'src/components/TooltipInline';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import SourceSelection from './sourceSelection';
import { Undo } from '@mui/icons-material';

const drawerWidth = 400;

const StepperWrapper = styled(Stepper)(
  ({ theme }) => `
    background-color: ${theme.palette.background.paper};
    .back-button {
      margin-top: 0px;
      padding-top: 3px;
      padding-bottom: 1px;
    }
  `
);

const valuesReadable = {
  'dmf': 'DMF',
  'carrier': 'Carrier-to-Carrier',
  'funeral-home-obituary': 'Obit Funeral Home',
  'newspaper-obituary': 'Obit Newspaper',
  'state': 'State'
}

const steps = [
  {
    label: 'Prospect',
    description: `Prospective customers actively involved in contract review and need access to see documentation.`,
  },
  {
    label: 'Onboarding',
    description:
      'Customers who have signed contracts and are ready to access the self-onboarding steps. ',
  },
  {
    label: 'Live',
    description: `Customers who completes onboarding steps and are ready to move live to start receiving matches.`,
  },
];

function Loading() {
  return (
    <Grid container spacing={3} sx={{ p: 3, textAlign: 'center' }}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <LoadingIcon contained={true} height="187px" marginTop="10%" />
      </Grid>
    </Grid>
  );
}

function CustomerAccountSetup() {

  const [activeStep, setActiveStep] = useState(0);
  const [saved, setSaved] = useState(true);

  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('id');
  const carrierStatus = searchParams.get('status');
  const companyName = searchParams.get('name');

  const [carrierConfig, setCarrierConfig] = useState([]);
  const [status, setStatus] = useState(null);
  const [matchesFileConfig, setMatchesFileConfig] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [provisioningStatus, setProvisioningStatus] = useState('prospect');
  const [beginProvisioning, setBeginProvisioning] = useState(false);
  const [sources, setSources] = useState(null);
  const [sourcesError, setSourcesError] = useState(false);
  const [stageChangedAlert, setStageChangedAlert] = useState(false);
  const [userChangingSteps, setUserChangingSteps] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [deleteConfigError, setDeleteConfigError] = useState(false);
  const [deletedConfigsSuccess, setDeletedConfigsSuccess] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const getProvisioningStatus = async function () {
    const provisioningStatus = await getResourceProvisioningStatus(carrierId);
    setProvisioningStatus(provisioningStatus.status);
  }

  useEffect(() => {
    const getConfig = async function () {
      const _config = await getCarrierConfiguration(carrierId);
      setCarrierConfig(_config);
      if (!userChangingSteps && _config) {
        if (_config['status'] === 'Prospect') {
          setStatus('Prospect');
          setActiveStep(0);
        } else if (_config['status'] === 'On-boarding') {
          setStatus('On-boarding');
          setActiveStep(1);
        } else if (_config['status'] === 'Live') {
          setStatus('Live');
          setActiveStep(2);
        }
      }
    };

    const getMatchFileConfig = async function () {
      if (provisioningStatus === 'complete') {
        const matchesFileConfigurations = await getMatchFileConfigurations(carrierId);
        setMatchesFileConfig(matchesFileConfigurations);
      }
    };

    Promise.all([
      getConfig(),
      getMatchFileConfig(),
      getProvisioningStatus()
    ]).finally(() => {
      setIsLoading(false);
    })
  }, [carrierId, provisioningStatus, isEditing]);

  useEffect(() => {
    if (provisioningStatus === 'processing') {
      const interval = setInterval(getProvisioningStatus, 60000);
      return () => clearInterval(interval)
    }
    else {
      setBeginProvisioning(false);
    }
  }, [provisioningStatus]);

  const handleNext = () => {
    setUserChangingSteps(true);
    const steps = activeStep + 1;
    setActiveStep(steps);
  };

  const handleBack = () => {
    const steps = activeStep - 1;
    setUserChangingSteps(true);
    setActiveStep(steps);
    setSaved(true);
  };

  const handleSave = () => {
    setSaved(true);
    setUserChangingSteps(false);
  }

  const handleSteps = async (steps) => {
    if (steps === 0) {
      await updateStatusCarrierConfiguration(carrierConfig['id'], { 'status': 'prospect' });
      setStatus('Prospect');
    } else if (steps === 1) {
      await updateStatusCarrierConfiguration(carrierConfig['id'], { 'status': 'on-boarding' });
      setStatus('On-boarding');
    } else if (steps === 2) {
      await updateStatusCarrierConfiguration(carrierConfig['id'], { 'status': 'live' });
      setStatus('Live');
    }
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    setStageChangedAlert(false);
  }

  const handleCheckbox = (event) => {
    setBeginProvisioning(event.target.checked);
  };

  const displayStageChangedAlert = () => {
    setStageChangedAlert(true);
    setTimeout(() => setStageChangedAlert(false), 5000);
  }

  const launchProvisioning = () => {
    let payload = {
      'name': companyName
    }

    const callProvisionResources = async function () {
      let provisioningStatus = await provisionResources(carrierId, payload);
      if (provisioningStatus) {
        setProvisioningStatus(provisioningStatus.status);
        handleSave();
        toggleEdit();
        setBeginProvisioning(false);
        setIsLoading(false);
      }
    }

    setIsLoading(true);
    callProvisionResources();
  }

  const cancelEdit = () => {
    setSourcesError(false);
    setBeginProvisioning(false);
    if (activeStep === 0 && status === 'On-boarding') {
      setActiveStep(1);
    }
    if (activeStep === 1 && status === 'Prospect') {
      setActiveStep(0);
    }
    if (activeStep === 1 && status === 'Live') {
      setActiveStep(2);
    }
    if (activeStep === 2 && status === 'On-boarding') {
      setActiveStep(1);
    }
    toggleEdit();
  }

  const callUpdateSources = async function () {
    if (sources?.length !== 0 && sources) {
      const payload = {
        sources: sources,
      }
      const response = await updateSourcesCarrierConfiguration(carrierConfig['id'], payload);

      return response;
    }
  }

  const moveToOnboarding = () => {
    if ((!carrierConfig?.sources || carrierConfig?.sources?.length === 0) && sources?.length === 0) {
      setSourcesError(true);
    }
    if (carrierConfig?.sources?.length !== 0 || sources?.length !== 0) {
      setIsLoading(true);
      Promise.all([
        callUpdateSources(),
        handleSteps(activeStep),
      ]).finally(() => {
        displayStageChangedAlert();
        setIsEditing(false);
        setIsLoading(false);
      })
    }
  }

  const handleSaveSteps = async () => {
    setIsLoading(true);
    await handleSteps(activeStep);
    displayStageChangedAlert();
    setIsEditing(false);
    setIsLoading(false);

  }

  const handleOpenModal = () => {
    setIsModal(true);
  }
  const handleCloseModal = () => {
    setIsModal(false);
  }
  const deleteAllConfigs = async function () {
    try {
      setIsDeleteLoading(true);
      await deleteAllConfigurations(carrierId);

      setMatchesFileConfig([]);
      handleCloseModal();
      setDeletedConfigsSuccess(true);

      setTimeout(() => {
        setDeletedConfigsSuccess(false);
        setIsDeleteLoading(false);
      }, 5000);

    }
    catch (error) {

      setIsDeleteLoading(false);
      setDeleteConfigError(true);

    }
  }
  return (
    <>
      <PageTitle title={companyName} />
      <Box sx={{ display: 'flex' }}>
        <SideNavigation drawerWidth={drawerWidth} selected="setup" />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 3 }, fontWeight: 700 }}>
            PRODUCT STAGES
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ mb: 2, mt: 1 }}>
                <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 0 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Customer’s Stage in LENS</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    {isEditing ?
                      <Button variant='text' color="primary" onClick={cancelEdit}>Cancel</Button>
                      :
                      <Button variant='outlined' color="primary" onClick={toggleEdit}>Change Stage</Button>
                    }
                  </Grid>
                </Grid>
                <hr />
                {isLoading ? <Loading /> :
                  <Grid container spacing={3} sx={{ pl: 3, pr: 3, pb: 1 }}>
                    <Grid item xs={12}>
                      {stageChangedAlert && <Alert onClick={() => setStageChangedAlert(false)} severity="success">
                        Stage changed successfully! All account's users now have access to this stage in LENS.</Alert>}
                      <StepperWrapper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                          <Step key={step.label} expanded={true}>
                            <StepLabel>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography variant="subtitle1" color={index <= activeStep ? 'text.primary' : 'text.secondary'} sx={{ display: 'inline-block' }}>
                                    {step.label} {index === 0 && activeStep === 0 && !isEditing && <TooltipInline tip="Moving from Prospect to Onboarding requires some backend setup which takes about 30 minutes to complete. Initiate this setup by clicking Change Stage and follow the instructions." useArrow={true} placement="right"></TooltipInline>}
                                  </Typography>
                                  {status === 'On-boarding' && <Chip className={(index === 1 && index === activeStep && saved) ? 'show' : 'hide'} size="small" color={(matchesFileConfig?.length > 0) ? 'success' : 'default'} label={(matchesFileConfig?.length > 0) ? 'Self-Onboarding Completed' : 'Self-Onboarding Initiated '} sx={{ ml: 2 }} />}
                                </Grid>
                                <Grid item xs={6}>
                                  {isEditing && (index === 1) && (index === activeStep) && status === 'On-boarding' &&
                                    <Button
                                      variant="text"
                                      size="small"
                                      className='back-button'
                                      onClick={handleBack}
                                      sx={{ mt: 1, mr: 1, display: 'inline-block', float: 'right' }}
                                    ><Undo sx={{ float: 'left', mt: '-3px', width: '18px' }} /> Revert back to Prospect </Button>
                                  }
                                  {isEditing && (index === 2) && (index === activeStep) &&
                                    <Button
                                      variant="text"
                                      size="small"
                                      className='back-button'
                                      onClick={handleBack}
                                      sx={{ mt: 1, mr: 1, display: 'inline-block', float: 'right' }}
                                    ><Undo sx={{ float: 'left', mt: '-3px', width: '18px' }} /> Revert back to Onboarding </Button>
                                  }
                                </Grid>
                              </Grid>
                            </StepLabel>
                            <StepContent>
                              <Typography color={index <= activeStep ? 'text.primary' : 'text.secondary'}>{step.description}</Typography>

                              {(isEditing || provisioningStatus === 'processing') &&
                                <Box>
                                  <div className={index === 0 && index === activeStep ? 'show' : 'hide'}>
                                    {(provisioningStatus === 'prospect' || provisioningStatus === 'failed') &&
                                      <>
                                        <Box sx={{ display: 'inline-block', float: 'left' }}><Checkbox checked={beginProvisioning} onChange={handleCheckbox} inputProps={{ 'aria-label': 'controlled' }} /></Box>
                                        <Box sx={{ display: 'block', mt: 2, maxWidth: '1060px' }}><Typography display="inline" variant="body1">I want LENS to start the backend setup for onboarding as the Client has executed an agreement and will shortly be ready to move to the Onboarding stage.</Typography></Box>
                                      </>
                                    }
                                    {provisioningStatus === 'processing' &&
                                      <Alert severity="info" sx={{ mt: 2 }}>
                                        Setup has begun and could take up to 30 minutes. When finished, this account can move to onboarding.</Alert>
                                    }
                                    {provisioningStatus === 'complete' && activeStep === 0 &&
                                      <Button
                                        variant="outlined"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }} 
                                      >
                                        Proceed to Onboarding
                                      </Button>
                                    }
                                  </div>
                                  {index === 1 && index === activeStep && (carrierConfig?.sources?.length === 0 || !carrierConfig?.sources) &&
                                    <SourceSelection carrierConfig={carrierConfig} setSources={setSources} status={status} sourcesError={sourcesError} setSourcesError={setSourcesError} />
                                  }
                                </Box>
                              }

                              {!isEditing &&
                                <Box>
                                  <div className={index === 0 && index === activeStep ? 'show' : 'hide'}>
                                    {provisioningStatus === 'failed' &&
                                      <Alert severity="error" sx={{ mt: 2 }}>
                                        An issue occurred while building the infrastructure for this account. Contact LENS support.</Alert>
                                    }
                                    {provisioningStatus === 'complete' &&
                                      <Alert severity="success" sx={{ mt: 2 }}>
                                        Setup successfully finished. When ready, this account can be moved to onboarding.</Alert>
                                    }
                                  </div>
                                </Box>
                              }

                              {index === 1 && index === activeStep && status === 'On-boarding' && carrierConfig?.['sources']?.length !== 0 &&

                                <>
                                  <Typography variant="subtitle1" sx={{ mt: 1 }}>Contracted sources for self-onboarding: </Typography>
                                  <Typography variant="body2" sx={{ mt: 1 }}>
                                    {carrierConfig?.['sources']?.map((value) => valuesReadable[value]).join(', ')}
                                  </Typography>
                                </>
                              }

                              {index === 1 && index === activeStep && isEditing &&
                                <div>
                                  <Button
                                    className={(index === 1 && index === activeStep && (matchesFileConfig?.length > 0)) ? 'show' : 'hide'}
                                    variant="outlined"
                                    onClick={handleNext}
                                    sx={{ mt: 3, mr: 1 }}
                                  >
                                    Proceed to Live
                                  </Button>
                                </div>
                              }

                            </StepContent>
                          </Step>
                        ))}
                      </StepperWrapper>
                    </Grid>
                  </Grid>
                }
                {provisioningStatus === 'complete' && !isLoading &&
                  <Grid container sx={{ pl: 3, pb: 3 }}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <Link to={`/customer-account-details?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>Manage sources</Link> for this customer on the account's Details page.
                      </Typography>
                    </Grid>
                  </Grid>
                }
                {(isEditing && !isLoading) && (

                  <Grid container>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      {(provisioningStatus === 'prospect' || provisioningStatus === 'failed') &&
                        <>
                          <hr />
                          <Button variant="contained" disabled={!beginProvisioning} onClick={launchProvisioning} sx={{ mt: 1, mr: 1, mb: 2 }}>
                            Save Changes
                          </Button>
                        </>
                      }
                      {activeStep === 0 && (provisioningStatus === 'processing' || provisioningStatus === 'complete') &&
                        <>
                          <hr />
                          <Button onClick={handleSaveSteps} variant="contained" disabled={status !== 'On-boarding'} sx={{ mt: 1, mr: 1, mb: 2 }}>
                            Save Changes
                          </Button>
                        </>
                      }
                      {(provisioningStatus === 'complete' && activeStep === 1) &&
                        <>
                          <hr />
                          <Button variant="contained" onClick={status === 'Live' ? handleSaveSteps : moveToOnboarding} sx={{ mt: 1, mr: 1, mb: 2 }}>
                            Save Changes
                          </Button>
                        </>
                      }
                      {(activeStep === 2) &&
                        <>
                          <hr />
                          <Button variant="contained" onClick={handleSaveSteps} sx={{ mt: 1, mr: 1, mb: 2 }}>
                            Save Changes
                          </Button>
                        </>
                      }
                    </Grid>
                  </Grid>
                )}
              </Paper>

              {!isEditing && (status === 'On-boarding') && <><Paper>
                {deletedConfigsSuccess && <Grid spacing={1} sx={{ pl: 4, pr: 4, pb: 0, pt: 4, mt: 4 }}><Alert severity="success">
                  Customer's saved configurations were deleted successfully, they can proceed with creating new configurations.
                </Alert></Grid>}
                <Grid container spacing={1} sx={{ pl: 4, pr: 4, pb: 1, pt: 0, mt: deletedConfigsSuccess ? 0 : 4 }}>

                  <Grid item xs={12} sm={6} >
                    <Typography variant='subtitle1' sx={{ mt: 2 }}>Delete customer's saved configurations?</Typography>
                    <Typography variant='body1' sx={{ mb: 2 }}>Customer will start over with creating new configurations.</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    <Button variant='outlined' size='medium' sx={{ mt: 2, mr: 2 }} color='error' onClick={handleOpenModal}>DELETE CONFIGURATIONS</Button>
                  </Grid>
                </Grid>
              </Paper>
                <Dialog
                  open={isModal}
                  onClose={handleCloseModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  sx={{ background: 'rgba(0,0,0,0.5)' }}
                >
                  <DialogTitle id="alert-dialog-title" variant='subtitle1' sx={{ mt: 2 }}>Delete customer's saved configurations?</DialogTitle>
                  {isDeleteLoading ? <Loading /> : <>
                    <DialogContent>
                      {deleteConfigError && <Alert onClick={() => setDeleteConfigError(false)} severity="error">
                        We're having trouble submitting your request to delete configurations. Please try again, or contact LENS support if the issue persists. </Alert>}
                      <DialogContentText id="alert-dialog-description" variant='body1' color={'black'}>
                        {companyName} will no longer see any previously saved configurations in LENS. They will start over with creating new configurations.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseModal}>NO, STAY ON PAGE</Button>
                      <Button variant='contained' onClick={deleteAllConfigs}>YES, PERMANENTLY DELETE</Button>
                    </DialogActions>
                  </>}
                </Dialog>
              </>}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CustomerAccountSetup;