import React from 'react';
import PropTypes from 'prop-types';

import '../../App.css';
import { Box, styled, Grid, Typography, Tooltip } from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const TitleWrapper = styled(Box)(
  ({ theme }) => `
      border-bottom: 1px solid rgba(0, 0, 0, 0.23);
      box-shadow: 0px 0px 0px 1px #e0e0e0;
      height: 120px;
      margin: -22px -27px 20px;
      padding: 40px 24px;

      .customer-add-btn {
        text-align: right;
      }
      .back-arrow {
        color: ${theme.palette.primary.dark};
      }

      @media screen and (max-width: 959px) {
        height: auto; 
        width: 100vw;
 
        .customer-add-btn {
          text-align: center;
          .MuiButton-root {
            width: 90vw !important;
          }
        }

        .MuiTypography-root.MuiTypography-h4 {
          font-size: 1.5rem;
        }
      }
      `
);

function PageTitle(props) {

  return (
    <TitleWrapper>
        <Grid className="" container spacing={2}>
          <Grid className="customer-accounts-title" item xs={12}>
            <Typography variant="h4" color="primary.dark"><Tooltip arrow title="Go Back"><Link to="/"><ArrowBackRounded className="back-arrow" /></Link></Tooltip>{props.title}</Typography>
          </Grid>
        </Grid>
    </TitleWrapper>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string
}

export default PageTitle;