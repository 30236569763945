import React, { useEffect, useState } from 'react';

import { Box, Button, ButtonGroup, Grid, Typography, Drawer, Backdrop, styled } from '@mui/material';

import SideNavigation from 'src/components/SideNavigation';
import PageTitle from 'src/components/PageTitle';
import { useSearchParams } from 'react-router-dom';
import customerListColumns from './customerListColumns';
import { getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations } from 'src/api';
import deathListColumns from './deathListColumns';
import { sourceDisplayMap } from 'src/utilities/mapSourceDisplay';
import CollapsibleTable from 'src/components/CollapsibleTable';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import CalendarDrawer from './CalendarDrawer';

const drawerWidth = 400;

const BackdropWrapper = styled(Box)(
  ({ theme }) => `
    .calendar-drawer-backdrop {
      opacity: 0.5 !important;
    }
`
);

function ActiveListSummary() {
  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('id');
  const [custFileConfig, setCustFileConfig] = useState([]);
  const initCompanyName = searchParams.get('name');
  const [deathFileConfig, setDeathFileConfig] = useState(null);
  const [matchesFileConfig, setMatchesFileConfig] = useState(null);
  const [custListTableData, setCustListTableData] = useState(null);
  const [deathListTableData, setDeathListTableData] = useState([]);
  const [showDeathList, setShowDeathList] = useState(false);
  const [openCalendarDrawer, setOpenCalendarDrawer] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [holidayCalendarId, setHolidayCalendarId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const openDrawer = () => {
    setOpenCalendarDrawer(true);
    setIsDrawerOpen(true);
  }

  const closeCalendarDrawer = () => {
    setIsDrawerOpen(false);
    setOpenCalendarDrawer(false);
  }

  useEffect(() => {
    const getCustFileConfig = async function () {
      const custFileConfigurations = await getCustomerFileConfigurations(carrierId);
      setCustFileConfig(custFileConfigurations);
      setIsLoading(false);
    };

    const getDeathFileConfig = async function () {
      const deathFileConfigurations = await getDeathFileConfigurations(carrierId);
      setDeathFileConfig(deathFileConfigurations);
    };

    const getMatchFileConfig = async function () {
      const matchesFileConfigurations = await getMatchFileConfigurations(carrierId);
      setMatchesFileConfig(matchesFileConfigurations);
    };

    Promise.all([
      getCustFileConfig(),
      getDeathFileConfig(),
      getMatchFileConfig()
    ])
  }, [carrierId]);

  useEffect(() => {
    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
    if (matchesFileConfig?.length > 0 && custFileConfig?.length > 0) {
      let arrayMap = matchesFileConfig?.map((item, index) => {
        const container = {};

        const matchedIndex = custFileConfig.findIndex(element => (element?.file?.prefix === item?.customerFilePrefix));
        const matchingCustomerFile = custFileConfig[matchedIndex];
        container.configurationType = item?.configurationType;
        container.customerList = item?.customerFilePrefix + '.' + matchingCustomerFile?.file?.type;
        container.id = index;
        container.matchWorkflow = item?.file?.prefix + '.' + item?.file?.type;
        container.annualDmf = item?.transfer?.annualDmf;
        container.sources = item?.sources.map((source) => sourceDisplayMap[source]).join(',\n');
        container.matchCategories = item?.categories;
        container.matchAuditDate = new Date(item?.auditDate).toLocaleDateString('en-US', dateOptions);
        container.matchAuditUser = item?.auditUser;
        container.matchFrequency = switchFreqDisplay(item?.transfer?.frequency);
        container.matchReportDates = item?.transfer?.reportDates;
        container.matchFrequencyDay = item?.transfer?.frequencyDay;
        container.matchHolidayCalendarId = item?.holidayCalendarId;
        container.matchNotificationEmails = item?.notificationEmails;
        container.matchTransferMethod = item?.transfer?.type;
        container.matchEncryptionEnabled = item?.transfer?.encryptionEnabled;
        container.matchNickname = item?.nickname;
        container.custAuditDate = new Date(matchingCustomerFile?.auditDate).toLocaleDateString('en-US', dateOptions);
        container.custAuditUser = matchingCustomerFile?.auditUser;
        container.custFrequency = switchFreqDisplay(matchingCustomerFile?.transfer?.frequency);
        container.custNotificationEmails = matchingCustomerFile?.notificationEmails;
        container.custTransferMethod = matchingCustomerFile?.transfer?.type;
        container.custEncryptionEnabled = matchingCustomerFile?.transfer?.encryptionEnabled;
        container.custNickname = matchingCustomerFile?.nickname;
        return container;
      })
      setCustListTableData(arrayMap);
    }

    if (deathFileConfig?.length > 0) {
      let arrayMap = deathFileConfig?.map((item, index) => {
        const container = {};

        container.configurationType = item?.configurationType;
        container.deathList = item?.file?.prefix + '.' + item?.file?.type;
        container.id = index;
        container.deathAuditDate = new Date(item?.auditDate).toLocaleDateString('en-US', dateOptions);
        container.deathAuditUser = item?.auditUser;
        container.deathFrequency = switchFreqDisplay(item?.transfer?.frequency);
        container.deathNotificationEmails = item?.notificationEmails;
        container.deathTransferMethod = item?.transfer?.type;
        container.deathEncryptionEnabled = item?.transfer?.encryptionEnabled;
        container.deathNickname = item?.nickname;

        return container;
      })
      setDeathListTableData(arrayMap);
    }
  }, [matchesFileConfig, deathFileConfig, custFileConfig]);

  return (
    <>
    <BackdropWrapper>
        <Backdrop
          className='calendar-drawer-backdrop'
          sx={{ color: '#fff', zIndex: 999 }}
          open={isDrawerOpen}
        />
      </BackdropWrapper>
    
      <PageTitle title={initCompanyName} />

      <Box sx={{ display: 'flex' }}>

        <SideNavigation drawerWidth={drawerWidth} selected="activeListSummary" />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 3 }, fontWeight: 700 }}>
            ACTIVE LIST SUMMARY
          </Typography>
          <ButtonGroup sx={{ mb: 4 }}>
            <Button onClick={() => setShowDeathList(false)} variant={showDeathList ? "outlined" : "contained"}>Customer Lists</Button>
            <Button onClick={() => setShowDeathList(true)} variant={showDeathList ? "contained" : "outlined"}>Verified Death Lists</Button>
          </ButtonGroup>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2} sx={{ pl: 0, pr: 2, pb: 1, pt: 0 }}>
                <Grid item xs={12}>
                  {!showDeathList &&
                    <CollapsibleTable columns={customerListColumns} data={custListTableData} message="No lists to display." openCalendarDrawer= {openDrawer} setHolidayCalendarId={setHolidayCalendarId} isLoading={isLoading} />
                  }
                  {showDeathList &&
                    <CollapsibleTable columns={deathListColumns} data={deathListTableData} message="No lists to display." isLoading={isLoading} />
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Drawer
        anchor='right'
        open={openCalendarDrawer}
        PaperProps={{sx: {width: "600px"}}}
        onClose={closeCalendarDrawer}
        sx={{zIndex: 99999}}
      >
        <CalendarDrawer closeCalendarDrawer={closeCalendarDrawer} holidayCalendarId={holidayCalendarId}/>
      </Drawer>
    </>
  );
}

export default ActiveListSummary;