import React, { useEffect, useState } from 'react';

import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import dayjs from "dayjs";
import { BusinessRounded, EditRounded, InfoOutlined, InfoRounded } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Paper, Typography, TextField, Tooltip, FormHelperText, Alert } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import SideNavigation from 'src/components/SideNavigation';
import DetailsWidgetSmall from './detailsWidgetSmall';
import EditSources from './editSources';
import TierWidget from './tierWidget';
import FrequencyWidget from './frequencyWidget';
import PageTitle from 'src/components/PageTitle';
import FieldValidationMessage from 'src/components/FieldValidationMessage';
import { getCarrierConfiguration, updateDetailsCarrierConfiguration, getAllCarrierConfigurations } from 'src/api';
import { useSearchParams } from 'react-router-dom';
import DisplayContractDates from 'src/components/DisplayContractDates';
import LoadingIcon from 'src/components/Loading/loadingIcon';

const drawerWidth = 400;


function CustomerAccountDetails() {
  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('id');
  const initCompanyName = searchParams.get('name');
  const carrierStatus = searchParams.get('status');
  const [carrierConfig, setCarrierConfig] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingSources, setEditingSources] = useState(false);
  const [updatedSources, setUpdatedSources] = useState(null);
  const [updatedCompanyName, setUpdatedCompanyName] = useState('');
  const [updatedCompanyWebsite, setUpdatedCompanyWebsite] = useState('');
  const [isLoadingCarriers,setIsLoadingCarriers] = useState(false);
  const [allCarrierConfigurations, setAllCarrierConfigurations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const sourcesToolTipText = "Customer selects from these sources during the match file setup in LENS."

  const sourcesMapping = {
    "carrier": "Carrier-to-Carrier",
    "dmf": "DMF",
    "funeral-home-obituary": "Obit Funeral Home",
    "newspaper-obituary": "Obit Newspaper"
  }

  useEffect(() => {
    const getConfigurations = async function () {
      const _allCarrierConfigurations = await getAllCarrierConfigurations();
      setAllCarrierConfigurations(_allCarrierConfigurations);
      setIsLoadingCarriers(false);
    };

    getConfigurations();
  }, [isLoadingCarriers]);

  yup.addMethod(yup.string, 'unique', function (message) {
    return yup.string().test('unique', message, function (website) {
      let obj = allCarrierConfigurations.find(o => _.chain(o.website).split('/').last().value() === _.chain(website).split('/').last().value() && _.chain(carrierConfig['website']).split('/').last().value() !== _.chain(website).split('/').last().value());
      return !obj;
    });
  });
  
  
  const validationSchema = yup.object().shape({
    companyName: yup
      .string()
      .max(25, 'Name exceeds the character limit (maximum is 25 characters')
      .required('This field is required'),
    companyWebsite: yup
      .string()
      .required('This field is required')
      .matches(/^(https:|http:)/, 'Include http:// or https:// at the beginning of the web address.')
      .unique('Website entered already exists. Try a different website.'),
    contractEndDate: yup
      .date()
      .nullable()
      .typeError('Incorrect format entered.')
      .when('contractStartDate', (contractStartDate) => {
        if (yup.date().required().isValidSync(contractStartDate)) {
          return yup.date().typeError('Incorrect format entered.').nullable().min(dayjs(contractStartDate).add(1, 'day').format('MM/DD/YYYY'), 'End date entered must be after the start date.').required('This field is required.');
        }
      }),
      contractStartDate: yup
        .date()
        .nullable()
        .typeError('Incorrect format entered.')
        .when('contractEndDate', (contractEndDate) => { 
          if (yup.date().required().isValidSync(contractEndDate)) {
            return yup.date().typeError('Incorrect format entered.').nullable().required('This field is required.')
          }
        }
      ),
  },['contractEndDate', 'contractStartDate']);

  function activateEditMode() {
    setIsEditing(true);
  }

  function deactivateEditMode() {
    setIsEditing(false);
    formik.resetForm()
  }

  function updateSources(sources) {
    setUpdatedSources(sources);
  }

  function editSources() {
    setEditingSources(true);
  }

  function cancelEditSources() {
    setEditingSources(false);
  }

  useEffect(() => {
    const getConfig = async function () {
      const _config = await getCarrierConfiguration(carrierId);
      setCarrierConfig(_config);
    };

    getConfig();
  }, [carrierId]);


  const formik = useFormik({
    initialValues: {
      companyName: isUpdated ? updatedCompanyName : initCompanyName,
      companyWebsite: isUpdated ? updatedCompanyWebsite : carrierConfig['website'],
      contractStartDate: carrierConfig?.contractBeginDate,
      contractEndDate: carrierConfig?.contractEndDate
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setApiError(false);
      setIsLoading(true);

      try {
        const payload = {
          name: values.companyName,
          website: values.companyWebsite
        }

        if (values.contractStartDate && values.contractEndDate) {
          payload.beginDate = values.contractStartDate;
          payload.endDate = values.contractEndDate;
        }

        const result = await updateDetailsCarrierConfiguration(carrierId, payload);

        if (result?.statusCode === 400 || result?.statusCode === 500) {
          setApiError(true);
        }
        else {
          setUpdatedCompanyName(formik.values.companyName);
          setUpdatedCompanyWebsite(formik.values.companyWebsite);
  
          const _config = await getCarrierConfiguration(carrierId);
          setCarrierConfig(_config);
  
          setIsUpdated(true);
          deactivateEditMode();
        }
      } catch (error) {
        setApiError(true);
      }
      
      setIsLoading(false);
    },
  });

  useEffect(() => {
    formik.validateForm();    
  }, [formik.values.contractStartDate, formik.values.contractEndDate])

  function formFields() {
    return (
      <>
        <Grid container spacing={3} sx={{ p: 3 }}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              label="Company Name"
              className="companyName"
              value={formik.values.companyName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.companyName)}
            />
            <FieldValidationMessage fieldTouched={formik.touched.companyName} validationMessage={formik.errors.companyName} />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <TextField
              fullWidth
              id="companyWebsite"
              name="companyWebsite"
              label="Company Website"
              className="companyWebsite"
              value={formik.values.companyWebsite}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.companyWebsite && Boolean(formik.errors.companyWebsite)}
            />
            <FieldValidationMessage fieldTouched={formik.touched.companyWebsite} validationMessage={formik.errors.companyWebsite} />
          </Grid>
          <Grid item xs={12}>
            <Typography color="text.secondary" mb={2}>Contract Dates (Optional)</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={formik.values.contractStartDate ? dayjs(formik.values.contractStartDate) : null}
                onChange={(date) => formik.setFieldValue('contractStartDate', date?.isValid() ? date.format('MM/DD/YYYY') : date)}
                onAccept={() => formik.setFieldTouched('contractStartDate', true)}
                sx={{width: '100%'}}
                slotProps={{
                  textField: {
                    error: formik.errors.contractStartDate && formik.touched.contractStartDate,
                  }
                }}
              />
              {formik.errors.contractStartDate && formik.touched.contractStartDate && <FormHelperText error sx={{ml: -1, fontWeight: 400}}><InfoOutlined sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />{formik.errors.contractStartDate}</FormHelperText>}
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                label="End Date"
                value={formik.values.contractEndDate ? dayjs(formik.values.contractEndDate) : null}
                onChange={(date) => formik.setFieldValue('contractEndDate', date?.isValid() ? date.format('MM/DD/YYYY') : date)}
                onAccept={() => formik.setFieldTouched('contractEndDate', true)}
                sx={{width: '100%'}}
                slotProps={{
                  textField: {
                    error: formik.errors.contractEndDate && formik.touched.contractEndDate,
                  },
                }}
              />
              {formik.errors.contractEndDate && formik.touched.contractEndDate && <FormHelperText error sx={{ml: -1, fontWeight: 400}}><InfoOutlined sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />{formik.errors.contractEndDate}</FormHelperText>}
            </LocalizationProvider>
          </Grid>
        </Grid>
        <hr />
        <Grid container sx={{ pt: 2, pb: 3 }}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button type="submit" variant="contained" color="primary">
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <PageTitle title={isUpdated ? updatedCompanyName : initCompanyName} />
      <Box sx={{ display: 'flex' }}>
        <SideNavigation drawerWidth={drawerWidth} selected="details" />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 4 }, fontWeight: 700 }}>
            DETAILS
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} sx={{mb: 2}}>
                  <Paper>
                    <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 0 }}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Company Profile</Typography>
                      </Grid>
                      {!isLoading &&
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                          {isEditing ? <Button onClick={deactivateEditMode} sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button> :
                            <Button onClick={activateEditMode} startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button>}
                        </Grid>}
                    </Grid>
                    <hr />
                    {apiError && <Alert sx={{ mx: 3, mt: 2 }} severity="error" onClick={() => setApiError(false)}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>}
                    {isLoading ? <LoadingIcon contained={true} height={300} /> :
                    <FormikProvider value={formik}>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3} sx={{ p: 3 }}>
                          <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Avatar variant="rounded" sx={{ margin: '0 auto 15px', backgroundColor: '#bdbdbd', width: '60px', height: '60px', borderRadius: '6px' }}>
                              <BusinessRounded sx={{ color: 'background.paper', fontSize: '30px' }} />
                            </Avatar>
                            <Typography variant="h5">
                              {isUpdated ? updatedCompanyName : formik.values.companyName}
                            </Typography>
                            {!isEditing &&
                              <Box sx={{ my: 3 }}>
                                <Typography variant="body1" sx={{ mb: 2 }}>
                                  {isUpdated ? updatedCompanyWebsite : formik.values.companyWebsite}
                                </Typography>
                                <DisplayContractDates beginDate={carrierConfig?.contractBeginDate} endDate={carrierConfig?.contractEndDate} />
                              </Box>
                            }
                          </Grid>
                        </Grid>
                        {isEditing && formFields()}
                      </form>
                    </FormikProvider>}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={12}>
                  <DetailsWidgetSmall label="Date Created" data={carrierConfig['dateAdded']} />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <DetailsWidgetSmall label="Account Status" accountStatus="Activated" activationDate={carrierConfig['dateAdded']} />
                </Grid>
                </Grid>
                </Grid>
            <Grid item xs={12} sx={{mt: 3}}>
              <TierWidget carrierId={carrierId} carrierConfig={carrierConfig} />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <Paper>
                <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1 }}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Contracted Sources</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    {(!editingSources && (carrierConfig.sources)) && <Button onClick={editSources} className="edit-sources" startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button>}
                    {editingSources && <Button onClick={cancelEditSources} className="cancel-edit-sources" sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button>}
                  </Grid>
                </Grid>
                <hr />
                <Grid className={editingSources ? 'hide' : 'show'} container spacing={3} sx={{ p: 3 }}>
                  <Grid item xs={12} sx={{ mb: 1, ml: 1 }}>
                    {((!carrierConfig?.sources) || (carrierConfig?.sources?.length === 0)) &&
                      <Typography variant="body1">
                        View sources here after account has moved out of the Prospect stage in LENS.
                      </Typography>
                    }
                    {carrierConfig?.sources?.length !== 0 &&
                      <>
                        {carrierStatus !== 'Prospect' &&
                          <Typography variant="subtitle1">
                            Sources for this customer account:
                            <Tooltip title={sourcesToolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
                          </Typography>
                        }
                        {(!updatedSources ? carrierConfig?.sources?.sort() : updatedSources?.sort())?.map((item) => (
                          <Typography key={item} variant='body1' sx={{ textTransform: sourcesMapping[item] || 'capitalize' }}>{sourcesMapping[item] || item}</Typography>
                        ))}
                      </>
                    }
                  </Grid>
                </Grid>
                {editingSources && <EditSources carrierConfig={carrierConfig} sources={carrierConfig?.sources} setEditingSources={setEditingSources} updateSources={updateSources} updatedSources={updatedSources} />}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <FrequencyWidget carrierId={carrierId} carrierConfig={carrierConfig} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CustomerAccountDetails;