import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography, FormControlLabel, FormControl, FormHelperText, Checkbox, Tooltip, Box, Switch } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { useFormik, FormikProvider, Field } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import WidgetCard from './widgetCard';
import { updateTransferFrequencies } from 'src/api';

const frequencyValues = [
  'daily',
  'weekly',
  'monthly',
  'quarterly',
  'once'
];
const frequencyLabels = [
  'Daily (Monday-Friday)',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Once'
];
const frequenciesMapping = {
  "daily": "Daily (Monday-Friday)",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "quarterly": "Quarterly",
  "once": "Once"
};
const frequenciesToolTipText = "Customer selects from these transfer frequencies when managing their match files in LENS.";

const validationSchema = yup.object().shape({
  frequencies: yup
    .array()
    .min(1, 'Field is required'),
  annualDmf: yup.bool()
})


function FrequencyWidget({ carrierId, carrierConfig }) {
  const [updatedFrequencies, setUpdatedFrequencies] = useState(null);
  const [updatedAnnualDmf, setUpdatedAnnualDmf] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);

  const formik = useFormik({
    initialValues: {
      frequencies: _.without(carrierConfig.scanFrequencies, 'dmf-annual'),
      annualDmf: carrierConfig?.scanFrequencies?.includes('dmf-annual')
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const scanFrequencies = _.chain(values.frequencies)
          .without('dmf-annual')
          .concat(values.annualDmf ? 'dmf-annual' : [])
          .value();

        const payload = { scanFrequencies };
        const result = await updateTransferFrequencies(carrierId, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setGeneralizedError(true);
        }
        else {
          setUpdatedFrequencies(values.frequencies);
          setUpdatedAnnualDmf(values.annualDmf);
          setGeneralizedError(false);
          setEditing(false);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setGeneralizedError(true);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue("frequencies", updatedFrequencies || _.without(carrierConfig.scanFrequencies, 'dmf-annual'));
    formik.setFieldValue("annualDmf", updatedAnnualDmf);
  }, [editing, carrierConfig?.scanFrequencies, updatedFrequencies, updatedAnnualDmf]);
  
  useEffect(() => {
    setUpdatedAnnualDmf(carrierConfig?.scanFrequencies?.includes('dmf-annual'))
  }, [carrierConfig?.scanFrequencies])

  const editingForm =
    <>
      <Typography variant="subtitle1">Frequencies for this customer account:
        <Tooltip title={frequenciesToolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
      </Typography>
      <FormHelperText sx={{ mt: 2, ml: -1 }} error={formik.errors.frequencies}>Minimum of one required.{formik.errors.frequencies ? "*" : ''}</FormHelperText>
      <FormControl component="fieldset">
        {frequencyValues?.map((frequencyValue, i) => {
          return <Field as={FormControlLabel} key={frequencyValue} type="checkbox" name='frequencies' label={frequencyLabels[i]} value={frequencyValue} control={<Checkbox />} sx={{ ml: 0 }} />
        })}
      </FormControl>
      {formik.errors.frequencies ?
        <FormHelperText sx={{ mt: 1, ml: -1 }} error={true}> <InfoRounded sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />*One frequency selection is required.</FormHelperText> : ''}
      <Box mt={2} mb={2}>
        <hr/>
      </Box>
      <Tooltip
        arrow
        placement="top"
        title={(!(carrierConfig?.contractBeginDate && carrierConfig?.contractEndDate) && "Full DMF Scans Unavailable") || (formik?.values?.annualDmf ? "Turn OFF Full DMF Scans" : "Turn ON Full DMF Scans")}
      >
        <span>
          <Switch
            name='annualDmf'
            disabled={Boolean(!(carrierConfig?.contractBeginDate && carrierConfig?.contractEndDate))}
            checked={formik?.values?.annualDmf}
            onChange={(event) => {formik.setFieldValue('annualDmf', event.target.checked)}} />
        </span>
      </Tooltip>
      <Typography sx={{display: 'inline'}} variant='subtitle1'>Annual Full DMF Scans</Typography>
      <Typography variant='body1'>Annual scans are delivered once per contract year in addition to regular scans.</Typography>
      <Typography variant='body2' color='text.secondary'>NOTE: Account’s contract dates must be provided under Company Profile to turn on full DMF scans.</Typography>
    </>

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <WidgetCard title='Match Results Transfer Frequencies' editingForm={editingForm} loading={loading} editing={editing} setEditing={setEditing} generalizedError={generalizedError} setGeneralizedError={setGeneralizedError}>
          <Typography variant="subtitle1">
            Frequencies for this customer account:
            <Tooltip title={frequenciesToolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
          </Typography>
          {(_.intersection(frequencyValues, updatedFrequencies || carrierConfig?.scanFrequencies).map((item) => (
            <Typography key={item} variant='body1'>{frequenciesMapping[item]}</Typography>
          )))}
          <Box mt={2} mb={2}>
            <hr/>
          </Box>
          {carrierConfig?.scanFrequencies &&
            <>
              <Typography><strong>Annual Full DMF Scans:</strong> {updatedAnnualDmf ? 'Enabled' : 'Disabled'}</Typography>
              <Typography>Annual scans are delivered once per contract year in addition to regular scans.</Typography>
            </>
          }
        </WidgetCard>
      </form>
    </FormikProvider>
  );
}

FrequencyWidget.propTypes = {
  carrierId: PropTypes.string,
  carrierConfig: PropTypes.object
}

export default FrequencyWidget;