import React, { useEffect, useState } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';

import { useAppContext } from 'src/AppContext.js';
import LoadingIcon from 'src/components/InitialLoader/loadingIcon';
import Layout from 'src/components/Layout';

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { oktaUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!authState) {
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if(!authState.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    else{
      setIsLoading(false);
    }

  }, [oktaAuth, oktaUser, authState, authState?.isAuthenticated]);

  return (
    <>
      { isLoading ? <LoadingIcon/> : <Layout><Outlet /></Layout>}
    </>
  );
};
