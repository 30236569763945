import PropTypes from 'prop-types';

import { Chip } from '@mui/material';

function CategoryChips ({ categories }) {
  return (
    <>
      {categories.map((cat) => {
        return <Chip key={cat} sx={{ mr: '3px', mb: '3px' }} label={cat} />
      })}
    </>
  );
}

CategoryChips.propTypes = {
  categories: PropTypes.array
}

export default CategoryChips;
