import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { AddRounded } from '@mui/icons-material';

function CustomToolbar(props) {
  return (
    <>
      {props?.title ?
        <Grid container sx={{ borderBottom: '1px solid #ddd', ml: 0 }}>
          <Grid item sm={6}>
            <Typography variant="body1" color="text.primary" sx={{ p: '30px 20px', fontWeight: 'bold' }}>
              {props?.title}
            </Typography>
          </Grid>
          <Grid item sm={6} sx={{ textAlign: 'right', p: 2 }}>
            {props?.customButton ? <Button className="customButton" LinkComponent={Link} to={props?.customButtonLink} variant="outlined" color="primary" startIcon={<AddRounded />}>{props?.customButtonText}</Button>
              : ''}
            {props?.customActionButton ? <Button className="customButton" onClick={props?.customButtonAction} variant="outlined" color="primary" startIcon={<AddRounded />}>{props?.customButtonText}</Button>
              : ''}
          </Grid>
        </Grid>
        :
        <>
        </>
      }
    </>
  );
}

export default CustomToolbar;

CustomToolbar.propTypes = {
  title: PropTypes.string,
  customButtonAction: PropTypes.func,
  customButton: PropTypes.bool,
  customButtonText: PropTypes.string,
  customActionButton: PropTypes.bool,
  customButtonLink: PropTypes.string
}