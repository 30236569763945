import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFormik, FormikProvider, Field } from 'formik';
import * as yup from 'yup';

import { Grid, Typography, FormControlLabel, Box, FormHelperText, Checkbox, FormControl } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import TooltipInline from 'src/components/TooltipInline';

const validationSchema = yup.object({
  source: yup
    .array()
    .required('This field is required'),
});

function SourceSelection({ carrierConfig, setSources, status, sourcesError, setSourcesError }) {
  const formik = useFormik({
    initialValues: {
      source: carrierConfig['sources']
    },
    validationSchema: validationSchema
  });

  useEffect(() => {
    if (sourcesError && formik?.values?.source?.length !== 0) {
      setSourcesError(false);
    }
    setSources(formik.values.source);
  }, [formik.values.source, setSources, sourcesError, setSourcesError]);

  return (
    <>
      <Grid container spacing={2} sx={{ pl: 0, pr: 2, pb: 1, pt: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Contracted sources for self-onboarding: <TooltipInline marginBottom='-15px' tip="Customer selects from these sources during the match file setup in LENS." useArrow={true} placement="right"></TooltipInline></Typography>
        </Grid>
      </Grid>
      {status === 'Prospect' &&
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} sx={{ pt: 1, pl: 0, pr: 3, pb: 0 }}>
              <Grid item sm={12}>
                <Typography variant="body1" color={(formik?.values?.source?.length === 0 && formik.touched.source) || sourcesError ? 'error.main' : 'text.secondary'}>
                  Minimum of one source required.*
                </Typography>

                <Box role="optgroup" aria-labelledby="checkbox-group">
                  <FormControl component="fieldset">
                    <Field as={FormControlLabel} type="checkbox" name='source' label='DMF' value='dmf' control={<Checkbox />} sx={{ ml: 1 }} />
                    <Field as={FormControlLabel} type="checkbox" name='source' label='Carrier-to-Carrier' value='carrier' control={<Checkbox />} sx={{ ml: 1 }} />
                    <Field as={FormControlLabel} type="checkbox" name='source' label='Obit Funeral Home' value='funeral-home-obituary' control={<Checkbox />} sx={{ ml: 1 }} />
                    <Field as={FormControlLabel} type="checkbox" name='source' label='Obit Newspaper' value='newspaper-obituary' control={<Checkbox />} sx={{ ml: 1 }} />
                    <Field as={FormControlLabel} type="checkbox" name='source' label='State' value='state' control={<Checkbox />} sx={{ ml: 1 }} />
                    {((formik?.values?.source?.length === 0 && formik.touched.source) || sourcesError) && <FormHelperText sx={{ mb: 4, mt: 1 }} error={true}> <InfoRounded sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />At least one source selection is required.</FormHelperText>}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      }
    </>
  );
}

SourceSelection.propTypes = {
  carrierConfig: PropTypes.object,
  setSources: PropTypes.func,
  status: PropTypes.string,
  sourcesError: PropTypes.string,
  setSourcesError: PropTypes.func
}

export default SourceSelection;