import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Button, Box, FormControl, Checkbox, FormControlLabel, FormHelperText, Alert, Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import { updateSourcesCarrierConfiguration } from 'src/api';

function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

const validationSchema = yup.object().shape({
  sources: yup
    .array()
    .min(1, 'Field is required')
})

function EditSources({ carrierConfig, sources, setEditingSources, updateSources, updatedSources }) {
  const [isLoading, setIsLoading] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const sourcesToolTipText = "Customer selects from these sources during the match file setup in LENS."

  const formik = useFormik({
    initialValues: {
      sources: updatedSources || sources,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const payload = { 'sources': values.sources };
        const result = await updateSourcesCarrierConfiguration(carrierConfig['id'], payload);
        
        if (result.statusCode === 400 || result.statusCode === 500) {
          setGeneralizedError(true);
        }
        else {
          updateSources(values.sources);
          setEditingSources(false);
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      {isLoading &&
        <Box sx={{ minHeight: '300px' }}>
          <Loading />
        </Box>
      }
      <Box className={isLoading ? 'hide' : 'show'}>
        <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ m: 2, mb:-2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ textAlign: 'left', width: '100%', px: 3 }}>
              <Typography variant="subtitle1" color="text.primary" sx={{ mt: 4, mb: 2 }}>
                Sources for this customer account:
                <Tooltip title={sourcesToolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
              </Typography>
              <FormHelperText sx={{ mb: 0, mt: 1, ml: -1 }} error={formik.errors.sources}>Minimum of one source required.*</FormHelperText>
              <Box sx={{ mb: 4, ml: -1}} role="optgroup" aria-labelledby="checkbox-group">
                <FormControl component="fieldset">
                <Field as={FormControlLabel} type="checkbox" name='sources' label='Carrier-to-Carrier' value='carrier' control={<Checkbox />} sx={{ ml: 1 }} />

                  <Field as={FormControlLabel} type="checkbox" name='sources' label='DMF' value='dmf' control={<Checkbox />} sx={{ ml: 1 }} />
                  <Field as={FormControlLabel} type="checkbox" name='sources' label='Obit Funeral Home' value='funeral-home-obituary' control={<Checkbox />} sx={{ ml: 1 }} />
                  <Field as={FormControlLabel} type="checkbox" name='sources' label='Obit Newspaper' value='newspaper-obituary' control={<Checkbox />} sx={{ ml: 1 }} />
                  <Field as={FormControlLabel} type="checkbox" name='sources' label='State' value='state' control={<Checkbox />} sx={{ ml: 1 }} />
                  {formik?.values?.sources?.length === 0 ? <FormHelperText sx={{ mb: 4, mt: 1 }} error={true}> <InfoRounded sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />*One source selection is required.</FormHelperText> : ''}
                </FormControl>
              </Box>
            </Box>
            <hr />
            <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
              <Button type="submit" variant="contained">
                Save Changes
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </>
  )
}

EditSources.propTypes = {
  carrierConfig: PropTypes.shape({
    id: PropTypes.string,
    product: PropTypes.string,
    claimKey: PropTypes.string
  }),
  sources: PropTypes.array,
  setEditingSources: PropTypes.bool,
  updateSources: PropTypes.func,
  updatedSources: PropTypes.array
};

export default EditSources;