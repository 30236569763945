import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Typography, Button, Alert } from '@mui/material';

import { reactivateOktaUser } from 'src/api';

import LoadingIcon from 'src/components/Loading/loadingIcon';

function Loading() {
  return (
    <LoadingIcon contained={true} height="187px" marginTop="6%" />
  );
}

function DetailsWidgetSmall(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [activationDate, setActivationDate] = useState(props?.activationDate);
  const [data, setData] = useState(props?.data);
  const dateCreated = props?.data;

  async function resendEmailInvite() {
    setGeneralizedError(false);
    setSuccessAlert(false);
    setIsLoading(true);
    const _result = await reactivateOktaUser(props?.carrierId, props?.userId);
    setIsLoading(false);
    if (_result.statusCode === 400 || _result.statusCode === 500) {
      setGeneralizedError(true);
    }
    else {
      setSuccessAlert(true);
      setTimeout(() => setSuccessAlert(false), 3000);
      setData(_result['status']);
      setActivationDate(_result['dateActivated']);
    }
  }
  return (
    <Paper>
      <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 0 }}>
        <Grid item sm={12}>
          <Typography variant="subtitle1">{props?.label}</Typography>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={3} sx={{ pt: 3, pl: 5, pr: 3, pb: 5 }}>
        <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mx: 2, mt: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
        <Alert severity="success" className={successAlert ? 'show' : 'hide'} sx={{ mx: 2, mt: 2 }}>Email invite sent.</Alert>
        {isLoading ? <Grid item><Loading /></Grid> :
          <>
            <Grid item sm={12}>
              <Typography variant="body1" sx={{textTransform: 'capitalize'}}>{props?.label === "LENS Access" ? <strong>Status: </strong> : ''}{props?.label === "LENS Access" ? data: dateCreated}</Typography>
              {props?.label === "LENS Access" && <Typography variant="body1" sx={{textTransform: 'capitalize'}}>{activationDate}</Typography>}
              {props?.label === "Account Status" && 
                <>
                  <Typography><b>LENS Access: </b>{props?.accountStatus}</Typography>
                  <Typography variant="body2">{props?.activationDate}</Typography>
                </>
              }
            </Grid>
            {props?.userLevel && (props?.data?.toLowerCase() === "invite expired" || props?.data?.toLowerCase() === "invited") ?
              <Grid item xs={12}>
                <Button onClick={resendEmailInvite} sx={{ marginTop: '-10px' }} variant="outlined" >RESEND EMAIL INVITE</Button>
              </Grid> : ''
            }
          </>
        }
      </Grid>
    </Paper>
  );
}

DetailsWidgetSmall.propTypes = {
  activationDate: PropTypes.string,
  data: PropTypes.object,
  carrierId: PropTypes.string,
  userId: PropTypes.string,
  label: PropTypes.string,
  userLevel: PropTypes.string,
  accountStatus: PropTypes.string
}

export default DetailsWidgetSmall;