import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';

import { ArrowBack, ContactsRounded, EditRounded } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material';

import DetailsWidgetSmall from 'src/pages/Customer Account Details/detailsWidgetSmall';
import NameField from 'src/components/FormFields/firstName';
import UserRoleField from 'src/components/FormFields/userRole';
import { updateOktaUser } from 'src/api';
import LoadingIcon from 'src/components/Loading/loadingIcon';

function Loading() {
  return (
    <LoadingIcon contained={true} height="187px" marginTop="6%" />
  );
}

function CustomerAccountEditUser({ backToUsers, currentUser, users, carrierConfig, reload }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedUserInfo, setUpdatedUserInfo] = useState(null);
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const clearError = () => {
    setIsDuplicateError(false);
  }
  yup.addMethod(yup.string, 'unique', function (message) {
    return yup.string().test('unique', message, function (email) {
      let obj = users.find(o => o.email === email);
      return (obj === undefined || email === currentUser?.email);
    });
  });

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .max(50, 'First Name must be 50 characters or less')
      .required('Field is required'),
    lastName: yup
      .string()
      .max(50, 'Last Name must be 50 characters or less')
      .required('Field is required'),
    emailAddress: yup
      .string()
      .email('Must enter a valid email address')
      .min(5, 'Email must be at least 5 characters')
      .max(100, 'Email must be 100 characters or less')
      .required('Field is required')
      .unique('Email entered already exists. Try a different email.'),
    lens_role: yup
      .string()
      .required(),
  });

  function activateEditMode() {
    if (updatedUserInfo) {
      formik.setFieldValue('firstName', updatedUserInfo?.firstName);
      formik.setFieldValue('lastName', updatedUserInfo?.lastName);
      formik.setFieldValue('emailAddress', updatedUserInfo?.email);
      formik.setFieldValue('lens_role', updatedUserInfo?.lens_roles[0].toLowerCase());
    }
    setIsEditing(true);
  }
  function deactivateEditMode() {
    Promise.all([
    formik.setFieldValue('firstName', currentUser?.firstName),
    formik.setFieldValue('lastName', currentUser?.lastName),
    formik.setFieldValue('emailAddress', currentUser?.email),
    formik.setFieldValue('lens_role', currentUser?.lens_roles[0].toLowerCase())
    ]).finally(() => {
      setIsEditing(false);
      formik.setErrors({});
    })
  }
  
  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      emailAddress: currentUser?.email,
      lens_role: currentUser?.lens_roles[0].toLowerCase()
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        let payload = {
          email: values?.emailAddress,
          firstName: values?.firstName,
          lastName: values?.lastName,
          lens_roles: [values?.lens_role]
        }

        const result = await updateOktaUser(carrierConfig?.id, currentUser?.id, payload);

        setIsLoading(false);

        if (result?.email) {
          reload();
          setUpdatedUserInfo({
            firstName: result?.firstName,
            lastName: result?.lastName,
            email: result?.email,
            lens_roles: result?.lens_roles
          });
          deactivateEditMode();
        }
        else if(result?.message === 'Api validation failed: login'){
          formik.setErrors({emailAddress: 'Email entered already exists. Try a different email.'});
        }
        else if (result?.message === 'Email domain does not match claim key') {
          formik.setErrors({emailAddress: 'Email domain does not match claimkey. Contact support to resolve.'});
        }
      }
      catch (e) {
        setIsLoading(false);
        deactivateEditMode();
      }
    },
  });

  function formFields() {
    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} sx={{ p: 3 }}>
            <Grid item sm={6} sx={{ textAlign: 'right' }}>
              <NameField name="firstName" label="First Name" formik={formik} />
            </Grid>
            <Grid item sm={6} sx={{ textAlign: 'left' }}>
              <NameField name="lastName" label="Last Name" formik={formik} />
            </Grid>
            <Grid onClick={clearError} item sm={12} sx={{ textAlign: 'center', mt: -2 }}>
            <NameField name="emailAddress"  isDuplicateError={isDuplicateError} duplicateHelperText={'Email entered already exists.'} label="Email Address" formik={formik} />
            </Grid>
            <Grid item sm={12} sx={{ textAlign: 'left', mt: -2 }}>
            <UserRoleField name="lens_role" formik={formik} field="lens_role" label={"Role"} fieldLabel={'Role'}/>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={3} sx={{ p: 3 }}>
            <Grid item sm={12} sx={{ textAlign: 'center' }}>
              <Button type="submit" variant="contained" color="primary">
                Update Profile
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: 6, fontWeight: 700 }}>
            MANAGE USER
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <Button onClick={backToUsers} variant="text" color="primary"><ArrowBack sx={{ mr: 3 }} />Back <Box sx={{ display: { xs: 'none', sm: 'block' } }}>&nbsp;to All Users</Box></Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Grid item xs={12}>
            <Paper sx={{ mb: 3 }}>
              <Grid container spacing={3} sx={{ pl: 3, pr: 3, pb: 1 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">User Profile</Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {!isEditing ? <Button onClick={activateEditMode} startIcon={<EditRounded />} sx={{ marginTop: '-10px' }} variant="outlined" >Edit</Button> : <Button onClick={deactivateEditMode} variant="text"  sx={{ marginTop: '-10px' }}>Cancel</Button>}
                </Grid>
              </Grid>
              <hr/>
              {isLoading ? <Loading /> :
                <Grid container spacing={3} sx={{ p: 3 }}>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Avatar variant="rounded" sx={{ margin: '0 auto 15px', backgroundColor: '#bdbdbd', width: '60px', height: '60px', borderRadius: '6px' }}>
                      <ContactsRounded sx={{ color: 'background.paper', fontSize: '30px' }} />
                    </Avatar>
                    <Typography variant="h5">
                      {updatedUserInfo ? updatedUserInfo?.firstName : formik.values.firstName} {updatedUserInfo ? updatedUserInfo?.lastName : formik.values.lastName}
                    </Typography>

                    {!isEditing && <Typography variant="body1" sx={{ mt: 4 }}>
                      {updatedUserInfo ? updatedUserInfo?.email : currentUser?.email}
                    </Typography>}
                    <Typography>Role: {updatedUserInfo ? updatedUserInfo.lens_roles.map((value)=>{ return value; })  : currentUser?.lens_roles.map((value)=>{ return value; })}</Typography>
                  </Grid>
                </Grid>
              }
              {(isEditing && !isLoading) && formFields()}
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailsWidgetSmall label="Date Created" data={currentUser?.dateCreated} />
          <DetailsWidgetSmall label="LENS Access" data={currentUser?.status} activationDate={currentUser?.dateActivated} userLevel={true} carrierId={carrierConfig?.id} userId={currentUser?.id}/>
        </Grid>
      </Grid>
    </>
  );
}

CustomerAccountEditUser.propTypes = {
  backToUsers: PropTypes.func, 
  currentUser: PropTypes.object, 
  users: PropTypes.array, 
  carrierConfig: PropTypes.object, 
  reload: PropTypes.func
}

export default CustomerAccountEditUser;