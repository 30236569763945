export function getTiersDisplay(tiers) {
  if (tiers){
    if (tiers.length > 1){
      return 'Plus + Verified';
    }
    if (tiers[0] === 'lens-verified'){
      return 'Verified';
    }
    if (tiers[0] === 'lens-plus'){
      return 'Plus';
    }
  }
  return 'Standard';
}