import { AddRounded } from '@mui/icons-material';
import { Box, Button, Grid, styled, Typography, Alert } from '@mui/material';
import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import TableWithActionButton from 'src/components/TableWithActionButton';
import LoadError from 'src/components/LoadError';
import { getAllCarrierConfigurations } from 'src/api/index.js';
import { useQuery } from 'src/hooks';
import columns from './columns';
import SkeletonRowCustomerAccounts from './SkeletonRowCustomerAccounts';

const TitleWrapper = styled(Box)(
  () => `
      border-bottom: 1px solid rgba(0, 0, 0, 0.23);
      box-shadow: 0px 0px 0px 1px #e0e0e0;
      height: 120px;
      margin: -22px -27px 30px;
      padding: 40px 24px;

      .customer-add-btn {
        text-align: right;
      }

      @media screen and (max-width: 599px) {
        height: auto; 
        width: 100vw;

        .customer-accounts-title {
          text-align: center;
        }
        .customer-add-btn {
          text-align: center;
          .MuiButton-root {
            width: 90vw !important;
          }
        }
        .MuiTypography-root.MuiTypography-h4 {
          font-size: 1.5rem;
      }
      }
      `
);

const TableWrapper = styled(Box)(
  () => `
    .MuiGrid-root .MuiTablePagination-displayedRows {
      display: block !important;
    }
    .MuiGrid-root .MuiInputBase-root {
      margin-right: 25px! important;
  }
  .MuiDataGrid-root {
    border-radius: 12px !important;
  }
  a:-webkit-any-link {
    text-decoration: none !important;
  }
  .company-name {
    padding-top: 17px;
  }
  @media screen and (max-width: 599px) {
    .MuiDataGrid-cell {
    min-width: 200px !important;
    }
    .MuiDataGrid-columnHeader {
      min-width: 200px !important;
    }
  }
      `
);

function CustomerAccounts() {
  const [searchParams] = useSearchParams();
  const accountSuccess = searchParams.get('accountSuccess');
  const companyName = searchParams.get('companyName')
  const [stateRefreshCounter, setStateRefreshCounter] = useState(0);
  const { data = [], isLoading, isError } = useQuery(['allCarrierConfigurations', stateRefreshCounter], () => getAllCarrierConfigurations());

  function reload() {
    const counter = (stateRefreshCounter + 1);
    setStateRefreshCounter(counter);
  }

  return (
    <>
      {(isError) && <LoadError reload={reload} />}
      <TitleWrapper>
        <Grid className="" container spacing={2}>
          <Grid className="customer-accounts-title" item xs={12} sm={7}>
            <Typography variant="h4" color="primary.text">Customer Accounts</Typography>
          </Grid>
          <Grid className="customer-add-btn" item xs={12} sm={5}>
            <Button LinkComponent={Link} to="/add-customer-account" variant="contained" color="primary" startIcon={<AddRounded />}>Create Customer</Button>
          </Grid>
        </Grid>
      </TitleWrapper>
      <Alert severity="success" className={accountSuccess ? 'show' : 'hide'} sx={{ mt: 2, mb: 2 }}>{`${companyName} created successfully!`}</Alert>
      <TableWrapper>
        <TableWithActionButton
          skeleton={<SkeletonRowCustomerAccounts />}
          isDataTableLoading={isLoading}
          rows={data}
          columns={columns}
          initialState={{ sorting: { sortModel: [{ field: 'dateAdded', sort: 'desc' }], }, }}
          rowHeight={60}
          tableHeight={798}
          pageSize={10}
          title="LENS Customer Management"
        />
      </TableWrapper>
    </>
  );
}

export default CustomerAccounts;