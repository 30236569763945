import React from 'react';

import { styled, Box, Grid } from '@mui/material';
import LogoArea from './LogoArea';
import HeaderUserbox from './Userbox';

const AppBarWrapper = styled(Box)(
  ({ theme }) => `
  .app-bar-container {
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
      z-index: 3;
      position: fixed;
      background-color: ${theme.sidebar.background};
      height: 80px;
  }
`
);


function AppBar() {
  return (
    <Grid item xs={12} sx={{ backgroundColor: '#fff' }}>
      <AppBarWrapper>
        <Grid className='app-bar-container' container spacing={2} sx={{ pl: 2, pr: 2 }}>
          <Grid item xs={10} sm={8} sx={{ pt: 3 }}>
            <LogoArea />
          </Grid>

          <Grid item xs={2} sm={4} sx={{ textAlign: 'right', mt: '6px' }}>
            <HeaderUserbox />
          </Grid>
        </Grid>
      </AppBarWrapper>
    </Grid>
  );
}

export default AppBar;