import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Radio, RadioGroup, FormControl, FormControlLabel, Typography, FormHelperText } from '@mui/material';

function UserRoleField(props) {

  return (
    <FormControl>
      <Grid container>
        <Grid item xs={12}>
          <Typography>{props?.fieldLabel}</Typography>
          <RadioGroup
            row
            id={props.name}
            name={props.name}
            value={props?.formik?.values?.[props?.field].toLowerCase()}
            onChange={props.formik.handleChange}
            error={props?.formik.touched?.[props?.name] && Boolean(props?.formik.errors?.[props?.name])}
          >
            <FormControlLabel value="collaborator" control={<Radio />} label="Collaborator" />
            <FormControlLabel value="administrator" control={<Radio />} label="Administrator" />
          </RadioGroup>
          <FormHelperText error={props?.formik.touched?.[props?.name] && Boolean(props?.formik.errors?.[props?.name])}>{props?.formik.touched?.[props?.name] && props?.formik.errors?.[props?.name] ? props?.formik.errors?.[props?.name] : ''}</FormHelperText>
        </Grid>

        <Grid xs={12} sx={{ border: 1, borderColor: 'rgba(0,0,0,.23)', p: 3 }}>
          <Typography variant='subtitle1' sx={{ pb: 2 }}>Role has access to these pages based on organization's stage in LENS.</Typography>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="flex-start" >
            <Grid item sm={4}>
              <Typography variant='subtitle2' sx={{ pb: 1 }}>PROSPECT STAGE</Typography>
              <Typography variant='subtitle1'>DOCUMENTATION</Typography>
              <Typography variant='body1'>
                Process Overview <br />
                Getting Started <br />
                Data Use Summary <br />
                Creating Customer List <br />
                Creating Verified Deaths List <br />
                Recieving Match Results <br />
                Helpful Guidance
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant='subtitle2' sx={{ pb: 1 }}>ONBOARDING STAGE</Typography>
              <Typography variant='subtitle1'>INITIAL FILE SETUP</Typography>
              {props?.formik?.values?.[props?.field].toLowerCase() === 'collaborator' && <Typography variant='subtitle2'>Self-onboarding steps to setup initial list configurations are done by an admin role. The collaborator role will only see the documentation pages during this stage.</Typography>}
              {props?.formik?.values?.[props?.field].toLowerCase() === 'administrator' && <>
                <Typography>
                  Customer List Setup<br />
                  Verified Deaths List Setup <br />
                  Match Results Setup <br />
                </Typography>
                <Typography variant='subtitle1'>MANAGE</Typography>
                <Typography>Holiday Calendar</Typography>
                <Typography variant='subtitle2'>Includes access to documentation pages.</Typography>
              </>}
            </Grid>
            <Grid item sm={4}>
              <Typography variant='subtitle2' sx={{ pb: 1 }}>LIVE STAGE</Typography>
              <Typography>
                Active List Summary <br />
                Activity Log
              </Typography>
              <Typography variant='subtitle1'>DATA LOADS & MATCH ACTIVITY</Typography>
              <Typography>
                Customer Lists <br />
                Verified Deaths <br />
                Match Results
              </Typography>
              <Typography variant='subtitle1'>MANAGE</Typography>
              <Typography>
                {props?.formik?.values?.[props?.field].toLowerCase() === 'administrator' &&
                  <>
                    List Configurations <br />
                    Transfer Locations & Credentials <br />
                    Encryption Keys <br />
                  </>
                }
                Holiday Calendar
              </Typography>
              <Typography variant='subtitle2'>Includes access to documentation pages.</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  )
}

UserRoleField.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object,
  field: PropTypes.string,
  fieldLabel: PropTypes.string
};

export default UserRoleField;