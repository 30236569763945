import React from 'react';

import PropTypes from 'prop-types';
import { Grid, styled } from '@mui/material';

import TableWithActionButton from 'src/components/TableWithActionButton';
import fileColumns from './DataGridColumns/fileColumns';
import matchesColumns from './DataGridColumns/matchesColumns';
import { ReactComponent as EmptyStateSvg } from 'src/images/EmptyStateDataSavedOn.svg'
import EmptyState from 'src/components/EmptyState/index.js';
import CollapsibleTable from 'src/components/CollapsibleTable';


import SkeletonRows from './SkeletonRows';

const GridWrapper = styled(Grid)(
  () => `

@media screen and (max-width: 768px) {
  .MuiDataGrid-cell {
  min-width: 150px !important;
  }
  .MuiDataGrid-columnHeader {
    min-width: 150px !important;
  }
}
`
)

function DataLoadsStatus({ isNewUser, isDataTableLoading, dataLoads, dataType, filterApplied }) {

  return (
    <GridWrapper>
      <div className={isNewUser === 'true' ? 'hide' : 'show'}>
        { dataType === 'deathFile' &&
          <TableWithActionButton
            skeleton={<SkeletonRows />}
            isDataTableLoading={isDataTableLoading}
            hideTable={dataLoads?.length === 0 && !isDataTableLoading}
            message="No file activity to display."
            rows={dataLoads}
            columns={fileColumns}
            rowHeight={100}
            tableHeight={700} />
        }
        { dataType === 'customerFile' &&
          <CollapsibleTable
            columns={fileColumns}
            data={dataLoads}
            message="No file activity to display."
            usePagination
            isLoading={isDataTableLoading} />
        }
        { dataType === 'matches' &&
          <CollapsibleTable
            columns={matchesColumns}
            data={dataLoads}
            message={filterApplied ? "No file activity found for the selected criteria." : "No file activity to display."}
            usePagination
            isLoading={isDataTableLoading} />
        }
      </div>
      <div className={isNewUser === 'true' ? 'show' : 'hide'}>
        <EmptyState icon={<EmptyStateSvg />} />
      </div>
    </GridWrapper>
  );
}

DataLoadsStatus.propTypes = {
  dataType: PropTypes.string.isRequired,
  isNewUser: PropTypes.string,
  isDataTableLoading: PropTypes.bool.isRequired,
  dataLoads: PropTypes.array,
  filterApplied: PropTypes.bool
};

export default DataLoadsStatus;
