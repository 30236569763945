import React from 'react';
import { Skeleton, Box } from '@mui/material';

const SkeletonRow = () => (
  <Box sx={{width: {xs: "200%", sm: '120%', md: '100%'} }}>
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, ml: 1, mr: 2, width: "5.3%", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: '5.6%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width:'25.7%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: '11.7%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: '5.5%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: '12.1%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: "12%", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, width: '10.5%', float: 'left' }} /> 
  </Box>
);

function SkeletonRowCustomerAccounts() {

  return (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  )
}

export default SkeletonRowCustomerAccounts;