import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography, RadioGroup, Radio, FormControlLabel, Box, Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { useFormik, FormikProvider } from 'formik';

import WidgetCard from './widgetCard';
import { updateTiers } from 'src/api';

import _ from 'lodash';

const tiers = {
  '': {
    label: 'LENS Standard Tier',
    description: 'Standard customers are responsible for their own customer list and for managing all records in the match results.'
  },
  'lens-plus': {
    label: 'LENS Plus Tier',
    description: 'Customer can send a customer file that doesn’t conform to specifications. The Data team will “clean up” the file and then process the file. The “clean up” will be limited to formatting the data, such as headers, split names into firstName, MiddleName, lastName, format date of birth to YYYYMMDD. The Data team will not enhance/augment the data - i.e. team will not add missing SSN or add missing DOBs. The team may however correct SSNs that appear to have the preceding 0 missing. Automated communication of customer file submission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  },
  'lens-verified': {
    label: 'LENS Verified Tier',
    description: 'The Match file will be verified by the Data team to ensure that the policyholder data matches the death record. Automated communication of match file transmission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  },
  'lens-plus,lens-verified': {
    label: 'LENS Plus + Verified Tier',
    description: 'Customer can send a customer file that doesn’t conform to specifications. The Data team will “clean up” the file and then process the file. The “clean up” will be limited to formatting the data, such as headers, split names into firstName, MiddleName, lastName, format date of birth to YYYYMMDD. The Data team will not enhance/augment the data - i.e. team will not add missing SSN or add missing DOBs. The team may however correct SSNs that appear to have the preceding 0 missing. The Match file will be verified by the Data team to ensure that the policyholder data matches the death record. Automated communication of customer file submission and match file transmission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  }
}
const toolTipText = "LENS tier is based on the customer’s contract with Evadata. Review contract before changing tier.";
const accessText = "Tier has access to documentation, file configurations, data loads and match activity.";

function TierWidget({ carrierId, carrierConfig }) {
  const [updatedTiers, setUpdatedTiers] = useState('');
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);

  useEffect(() => {
    if(carrierConfig.tiers){
      const tiers = _.join(carrierConfig?.tiers);
      setUpdatedTiers(tiers);
    }
  }, [carrierConfig]);

  const formik = useFormik({
    initialValues: {
      tiers: _.join(carrierConfig.tiers)
    },
    enableReinitialize: true,
    onSubmit: async () => {
      setLoading(true);
      let tiers = _.split(formik.values.tiers, ",");
      tiers = tiers[0] === "" ? [] : tiers; 
      const result = await updateTiers(carrierId, {tiers});
      if (result.statusCode === 400 || result.statusCode === 500) {
        setGeneralizedError(true);
      }
      else {
        setUpdatedTiers(result.tiers);
        setGeneralizedError(false);
        setEditing(false);
      }
      setLoading(false);
    }
  });

  useEffect(() => {
    if(updatedTiers) {
      formik.setFieldValue("tiers", updatedTiers);
    }
  }, [editing, updatedTiers]);

  const editingForm = 
    <>
      <Typography variant="subtitle1">Assign LENS tier for this customer account:
        <Tooltip title={toolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
      </Typography>
      <RadioGroup 
        row 
        id="tiers" 
        name="tiers" 
        value={formik?.values?.tiers} 
        onChange={formik?.handleChange}
        sx={{ m: 2 }}
      >
        <FormControlLabel value="" control={<Radio/>} label="Standard" />
        <FormControlLabel value="lens-plus" control={<Radio/>} label="Plus" />
        <FormControlLabel value="lens-verified" control={<Radio/>} label="Verified" />
        <FormControlLabel value="lens-plus,lens-verified" control={<Radio/>} label="Plus + Verified" />
      </RadioGroup>
      <Box sx={{ border: '1px solid lightgrey', bgcolor: 'background.default' }} p={3} mt={1}>
        <Typography variant='overline' sx={{ mb: 1, color: 'text.primary' }}>{tiers[formik?.values?.tiers].label}</Typography>
        <Typography>{tiers[formik?.values?.tiers].description}</Typography>
        <Typography>{accessText}</Typography>
      </Box>
      <Typography sx={{ mt: 2, mb: -1 }}>When making a tier change, contact the customer to communicate how this may effect their current workflow.</Typography>
    </>

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <WidgetCard title='LENS Tier' editingForm={editingForm} loading={loading} editing={editing} setEditing={setEditing} generalizedError={generalizedError} setGeneralizedError={setGeneralizedError}>
          {(updatedTiers || updatedTiers === '') &&
            <>
              <Typography variant='subtitle1'>{tiers[updatedTiers]?.label}</Typography>
              <Typography>{tiers[updatedTiers]?.description}</Typography>
              <Typography>{accessText}</Typography>
            </>
          }
        </WidgetCard>
      </form>
    </FormikProvider>
  );
}

TierWidget.propTypes = {
  carrierId: PropTypes.string,
  carrierConfig: PropTypes.object
}

export default TierWidget;