import CategoryChips from "src/components/CategoryChips";

import { Box } from "@mui/material";

const activityLogColumns = [
  {
    field: 'fileName',
    headerName: 'Match Workflow',
    flex: 1.4,
    sortable: false,
    renderCell: (params) => {
      if (params.row.fileNameNickname != null) {
        return <div className="wrap-cell"><p>{params.row.fileNameNickname}<br />{params.value}</p></div>;
      }
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  },
  {
    field: 'sortableTime',
    headerName: 'Time',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.row.time}</p></div>;
    },
  },
  {
    field: 'sources',
    headerName: 'Sources',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  {
    field: 'categories',
    headerName: 'Match Categories',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <Box mt={1} mb={1}>
          <div className="wrap-cell"><CategoryChips categories={params.value} /></div>
        </Box>
      );
    }
  },
  {
    field: 'enteredBy',
    headerName: 'Entered By',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  }
]

export default activityLogColumns;