import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { FileDownloadRounded, HourglassTopRounded } from '@mui/icons-material';
import fileDownload from 'js-file-download';
import { useSearchParams } from 'react-router-dom';

import { getErrorFileSignedUrl } from '../../../api';

function ErrorFileDownload({fileType, dataLoadId}) {
  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('id');

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleDownload = async () => {
    try {
      setDownloadInProgress(true);
      setHasError(false);
      const { url, filename } = await getErrorFileSignedUrl(carrierId, fileType, dataLoadId);
      const result = await fetch(url);
      const data = await result.blob();

      fileDownload(data, filename);

      setDownloadInProgress(false);
    } catch (error) {
      // do something with the error
      console.log(error, hasError); // remove this console when we have error handling done
      setDownloadInProgress(false);
      setHasError(true);
    }
  };

  return (
    <>
    {!downloadInProgress && <FileDownloadRounded onClick={handleDownload} key={dataLoadId} className="download-arrow" color="primary" />}
    {downloadInProgress && <HourglassTopRounded className="hourglass" color="primary" />}
    </>
  )
}

ErrorFileDownload.propTypes = {
  fileType: PropTypes.string, 
  dataLoadId: PropTypes.string
}

export default ErrorFileDownload;