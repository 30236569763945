import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useFormik, FormikProvider, Field } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import PageTitle from 'src/components/PageTitle';
import SelectCategories from 'src/components/SelectCategories'
import LoadingIcon from 'src/components/Loading/loadingIcon';
import ErrorAlert from 'src/components/ErrorAlert';
import SideNavigation from 'src/components/SideNavigation';
import SelectSources from 'src/components/SelectSources';
import { getCustomerFileConfigurations, getMatchFileConfigurations, initiatePrescan } from 'src/api';

import { Box, Button, Grid, Paper, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormHelperText, styled, Alert, IconButton } from '@mui/material';
import { CheckCircleOutline, Close } from '@mui/icons-material';


const drawerWidth = 400;

function Loading() {
  return (
    <Grid container spacing={3} sx={{ p: 3, textAlign: 'center' }}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <LoadingIcon contained={true} height="187px" marginTop="10%" />
      </Grid>
    </Grid>
  );
}

function SuccessAlert({ close, carrierId, carrierStatus, companyName }) {
  return (
    <AlertWrapper>
      <Alert
        iconMapping={{
          success: <CheckCircleOutline fontSize="inherit" />,
        }}
        className="completed-alert"
        severity="success"
        action={
          <IconButton className="alert-close"
            aria-label="close"
            color="inherit"
            size="small"
            onClick={close}
          >
            CLOSE <Close className='close-icon' fontSize="inherit" />
          </IconButton>
        }>Prescan submitted successfully. Submit another prescan or view file's status at <Link to={`/customer-account-file-activity?id=${carrierId}&status=${carrierStatus}&name=${companyName}&tab=match`}>Data Loads & Match Activity</Link>
      </Alert>
    </AlertWrapper>
  );
}

SuccessAlert.propTypes = {
  close: PropTypes.func,
  carrierId: PropTypes.string,
  carrierStatus: PropTypes.string,
  companyName: PropTypes.string
}

const AlertWrapper = styled(Box)(
  () => `
    .alert-close {
      font-size: 13px;
    }
    .close-icon {
      margin-left: 3px;
      font-size: 20px;
    }
    `
);

function Prescan() {
  const [searchParams] = useSearchParams();
  const companyName = searchParams.get('name');
  const carrierId = searchParams.get('id');
  const carrierStatus = searchParams.get('status');
  const [custFileConfigs, setCustFileConfigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [resetSources, setResetSources] = useState(false);


  useEffect(() => {
    const getCustFileConfigs = async function () {
      return getCustomerFileConfigurations(carrierId);
    };

    const getMatchFileConfigs = async function () {
      return getMatchFileConfigurations(carrierId);
    };

    Promise.all([
      getCustFileConfigs(),
      getMatchFileConfigs()
    ]).then((configs) => {
      const activeCustFilePrefixes = _.map(configs[1], 'customerFilePrefix');
      const _custFileConfigs = _.filter(configs[0], (config) => _.includes(activeCustFilePrefixes, config.file.prefix));
      setCustFileConfigs(_custFileConfigs);
      setLoading(false);
    });
  }, [carrierId]);

  const validationSchema = yup.object().shape({
    customerFilePrefix: yup
      .string()
      .required(),
    sources: yup
      .array()
      .min(1),
    categories: yup
      .array()
      .min(1)
  });

  const formik = useFormik({
    initialValues: {
      customerFilePrefix: "",
      sources: [],
      categories: []
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (formValues, { resetForm }) => {
      setApiError(false);
      setShowSuccess(false);
      setSubmitLoading(true);
      try {
        const result = await initiatePrescan(carrierId, formValues)

        if (result.statusCode === 400 || result.statusCode === 500) {
          setApiError(true);
          window.scrollTo(0, 0);
        }
        if (result.success) {
          setShowSuccess(true);
          resetForm();
          setFormTouched(false);
          setResetSources(false);
          setShowErrorAlert(false);
        } else {
          setApiError(true);
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.log(error)
        setApiError(true);
      }
      setSubmitLoading(false);
    },
  });

  function updateSelectedCategories(allSelected) {
    formik.setFieldValue("categories", allSelected);
  }

  const validate = () => {
    if (formik.errors) {
      setFormTouched(true);
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
    else {
      setShowErrorAlert(false);
    }
  }

  function errorMessageVisible(formikValue) {
    return Boolean(formik.touched[formikValue] && formik.errors[formikValue]);
  }

  function close() {
    setShowSuccess(false);
  }

  return (
    <>
      {submitLoading && <LoadingIcon />}
      <FormikProvider value={formik}>
        <ErrorAlert formik={formik} showErrorAlert={showErrorAlert} />
        <form onSubmit={formik.handleSubmit}>
          <PageTitle title={companyName} />
          <Box sx={{ display: 'flex' }}>
            <SideNavigation drawerWidth={drawerWidth} selected="prescan" />
            <Box
              component="main"
              sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
            >
              <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: 1, fontWeight: 700 }}>
                LIST CONFIGURATION PRESCAN
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} mb={1}>
                  {showSuccess && <SuccessAlert close={close} carrierId={carrierId} companyName={companyName} carrierStatus={carrierStatus}/>}
                  {apiError && <Alert severity="error" onClick={() => setApiError(false)}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>}
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Paper>
                    <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 0 }}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="text.primary" fontWeight={700}>Request Prescan to View Match Counts</Typography>
                      </Grid>
                    </Grid>
                    <hr />
                    {loading ? <Loading /> :
                      <>
                        <Grid container spacing={3} sx={{ p: 3, pl: 4 }}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" color="text.secondary" name="customerFilePrefix">LIST CONFIGURATION</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ pb: 1 }}>Choose which customer list to run the prescan against:</Typography>
                            <FormControl sx={{ pb: 3 }}>
                              <FormHelperText error={errorMessageVisible('customerFilePrefix')}>
                                <Typography variant="body2" ml={-2}>Minimum of one list required.{errorMessageVisible('customerFilePrefix') && '*'}</Typography>
                              </FormHelperText>
                              <RadioGroup>
                                {custFileConfigs.map((config) => {
                                  const fileName = `${config.file.prefix}.${config.file.type}`;
                                  const label = config.nickname ? `${config.nickname} (${fileName})` : fileName;
                                  return <Field sx={{ pl: 1 }} as={FormControlLabel} name="customerFilePrefix" key={config.file.prefix} value={config.file.prefix} control={<Radio />} checked={config.file.prefix === formik?.values?.customerFilePrefix} label={label} />
                                })}
                              </RadioGroup>
                              <FormHelperText error={errorMessageVisible('customerFilePrefix')}>
                                <Typography fontSize="13px" ml={-2}>{errorMessageVisible('customerFilePrefix') ? '*At least one list selection is required.' : 'Shown are only actively running customer lists.'}</Typography>
                              </FormHelperText>
                            </FormControl>
                            <hr />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" color="text.secondary" name="sources">SOURCES</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ pb: 1 }}>Sources customer records will be matched against for the prescan:</Typography>
                            <SelectSources formik={formik} />
                            <hr />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" color="text.secondary" sx={{ pb: 3 }} name="categories">MATCH CATEGORIES</Typography>
                            <SelectCategories updateSelectedCategories={updateSelectedCategories} formTouchedProp={formTouched} existingCategories={[]} initialized={resetSources} setInitialized={setResetSources} />
                          </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={2} sx={{ p: 1 }} textAlign="center">
                          <Grid item xs={12}>
                            <Typography>
                              Submissions will start immediately, with results available same day. View match file status at <Link to={`/customer-account-file-activity?id=${carrierId}&status=${carrierStatus}&name=${companyName}&tab=match`}>Data Loads & Match Activity</Link>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sx={{ pb: 2 }}>
                            <Button type="submit" onClick={validate} variant="contained">Submit Prescan Request</Button>
                          </Grid>
                        </Grid>
                      </>
                    }
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </FormikProvider>
    </>
  );
}

export default Prescan;