import React, {useMemo, useState} from 'react';

import { Grid } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from 'react-query';

import { AppContext } from './AppContext'; 
import './App.css';

import AppRoutes from './AppRoutes';


function App() {  
  const queryClient = new QueryClient();
  const [oktaUser, setOktaUser] = useState(null);

  const provider = useMemo(() => ({
    oktaUser,
    setOktaUser
  }), [oktaUser]);

  return (
    <AppContext.Provider value={provider}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <CssBaseline />
          <Grid>
            <AppRoutes/>  
          </Grid>  
        </div>
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
