import { Typography, MenuItem, Chip } from "@mui/material";
import DisplayManageCustomerAccount from "src/components/DisplayManageCustomerAccount";
function getUserColumns(callManageUsers) {

  function MenuItemList(row){
    return(
      <MenuItem onClick={() => callManageUsers(row)} sx={{p: 0, minWidth: '30px'}} aria-controls={'edit-field'}>Manage User</MenuItem>
    );
  }
const sortDate = (date1,date2) => new Date(date1) - new Date(date2);
const sortRoles = (roles1,roles2) => {
  if(roles1[0]===roles2[0]){
    return 0;
  } 
   
  return roles1[0] > roles2[0] ? 1 : -1;
}
const columns = [
  { 
    field: 'edit', 
    headerName: 'Actions', 
    flex: 0.8,
    sortable: false,
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell edit">
        <DisplayManageCustomerAccount MenuItemList={() => MenuItemList(params.row)}/>
        </div>;
    } 
  },
    { 
    field: 'firstName', 
    headerName: 'First Name', 
    flex: 1.2,
    sortable: true,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell">
        <Typography sx={{pt: 2}} variant="body2">{params.value}</Typography>
        </div>;
    } 
  },
    { 
    field: 'lastName', 
    headerName: 'Last Name', 
    flex: 1.2,
    sortable: true,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell">
        <Typography sx={{pt: 2}} variant="body2">{params.value}</Typography>
        </div>;
    } 
  },
  { 
    field: 'email', 
    headerName: 'Email', 
    flex: 2.2,
    sortable: true,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell">
        <Typography sx={{pt: 2}} variant="body2">{params.value}</Typography>
        </div>;
    } 
  },
    { 
    field: 'lens_roles', 
    headerName: 'Roles', 
    flex: 2.2,
    sortable: true,
    sortingOrder: ['desc','asc'],
    sortComparator: sortRoles,
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell company-name">
       {params.value.map((role) => (<Chip key={role} sx={{mr: 1}}label={role}/>))}
        </div>;
    } 
  },
  { 
    field: 'dateCreated', 
    headerName: 'Date Created', 
    flex: 1.2,
    sortable: true,
    sortingOrder: ['desc','asc'],
    sortComparator: sortDate,
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell">
        <Typography sx={{pt: 2}} variant="body2">{params.value}</Typography>
        </div>;
    } 
  },
  { 
    field: 'status', 
    headerName: 'LENS Access', 
    flex: 1.5,
    sortable: true,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      const colors={
        'Invited' : 'primary',
        'Active' : 'success',
        'Invite Expired' : 'error'
      }
      return <div className="wrap-cell date">
        <Chip color={colors[params.value]}  variant="outlined" label={params.value}/>
        </div>;
    } 
  },
];

return columns;

}

export default getUserColumns;