import { MoreHorizRounded } from "@mui/icons-material";
import { IconButton, Menu, Tooltip} from "@mui/material";
import { useState } from "react";
import PropTypes from 'prop-types';

function DisplayManageCustomerAccount({MenuItemList}){
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {setAnchorEl(event.currentTarget);};
  const handleClose = () => {setAnchorEl(null);};
  
  return (<>
            <Tooltip arrow title="More Options">
            <IconButton id='action-button' aria-controls={open ? 'action-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} variant="contained" onClick={(e) => handleClick(e)}>
              <MoreHorizRounded />
            </IconButton>
            </Tooltip>
            <Menu disableScrollLock id="action-menu" MenuListProps={{ 'aria-labelledby': 'action-button', }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{ style: { flex: 1 }, }}>
              {MenuItemList()}
            </Menu>
          </>)
}

DisplayManageCustomerAccount.propTypes = {
  MenuItemList: PropTypes.func
}

export default DisplayManageCustomerAccount;

