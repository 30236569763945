const redirectUri = `${window.location.origin}/login/callback`;

const okta = {
  oidc: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    issuer: process.env.REACT_APP_ISSUER,
    redirectUri: redirectUri,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false
  }
};

export default okta;
