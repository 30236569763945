import PropTypes from 'prop-types';

import { Typography } from '@mui/material';


function DisplayContractDates({ beginDate, endDate }) {
  const contractDateFormat = new Intl.DateTimeFormat('en-US', { year: "numeric", month: "long", day: "numeric" });

  return (
    <Typography variant="body1">
      <strong>Contract Dates: </strong>
      {beginDate && endDate ? `${contractDateFormat.format(Date.parse(beginDate))} - ${contractDateFormat.format(Date.parse(endDate))}` : 'Add dates before moving account to Live stage.'}
    </Typography>
  );
}

DisplayContractDates.propTypes = {
  beginDate: PropTypes.string,
  endDate: PropTypes.string
};

export default DisplayContractDates;