import { Chip } from '@mui/material';
import FileDownloadCard from 'src/components/FileDownload';


const matchesColumns = [
  { field: 'fileName', headerName: 'File Name', flex: 1.5 },
  {
    field: 'id',
    headerName: 'Dataload ID',
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'submitted',
    headerName: 'Processing Completed',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'completed',
    headerName: 'Results Sent',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'totalMatches', 
    headerName: 'Match Records', 
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'fileStatus',
    headerName: 'File Status',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      const colors  = {
        'Processing': 'info',
        'Failed': 'error',
        'Errors Found': 'warning',
        'Successful': 'success'
      };
      
      const color = colors[params?.value] || '';
      return <Chip size="small" color={color} className={params?.value} label={params?.value} variant="outlined" />;
    },
  },
  {
    field: 'downloadFile',
    headerName: 'Download',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params?.value) {
        return <FileDownloadCard variation="icon" fileType="CSV" fileName="FileName.csv" file={params?.value} />;
      }
      
    },
  }
];

export default matchesColumns;