import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BusinessRounded, ContactsRounded, HistoryRounded, LibraryAddCheckRounded, TextSnippetRounded, ViewListRounded, SettingsSuggestRounded } from '@mui/icons-material';
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Toolbar } from '@mui/material';

function SideNavigation(props) {
  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('id');
  const carrierStatus = searchParams.get('status');
  const companyName = searchParams.get('name');

  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const DrawerWrapper = styled(Box)(
    ({ theme }) => `

  .MuiDrawer-docked .MuiDrawer-paper {
    background: transparent;
    width: 280px;
    position: relative;
    margin-left: -30px;
    margin-top: -20px;
    } 
    .MuiDrawer-paper {
      border-right: 0px;
      z-index: 1;
    }
    .icon {
      color: ${theme.palette.action.active};
    }
    .MuiListItemIcon-root {
      min-width: 37px;
    }
    .MuiListItem-root {
      height: 50px;
      margin-bottom: 10px;
      border-radius: 6px;
      font-weight: 700;
  }
  .Mui-selected  {
    background-color: ${theme.palette.primary.dark} !important;
    color: #fff;
    &:hover {
      background-color: ${theme.palette.primary.main} !important;
    }
    .icon {
      color: #fff;
    }
    .MuiListItemButton-root:hover {
      background-color: transparent;
    }
  }

  .temp-drawer .MuiDrawer-paper {
    margin-top: 62px !important;
}
    
    `

  )

  const drawer = (
    <List>
      <ListItem key="Details" disablePadding selected={props.selected === 'details'}>
        <ListItemButton LinkComponent={Link} to={`/customer-account-details?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>
          <ListItemIcon>
            <BusinessRounded className="icon" />
          </ListItemIcon>
          <ListItemText>Details</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem key="Setup" disablePadding selected={props.selected === 'setup'}>
        <ListItemButton LinkComponent={Link} to={`/customer-account-setup?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>
          <ListItemIcon>
            <LibraryAddCheckRounded className="icon" />
          </ListItemIcon>
          <ListItemText>Product Stages</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem key="Active List Summary" disablePadding selected={props.selected === 'activeListSummary'}>
        <ListItemButton LinkComponent={Link} to={`/active-list-summary?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>
          <ListItemIcon>
            <ViewListRounded className="icon" />
          </ListItemIcon>
          <ListItemText>Active List Summary</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem key="Activity Log" disablePadding selected={props.selected === 'activityLog'}>
      <ListItemButton LinkComponent={Link} to={`/activity-log?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>
          <ListItemIcon>
            <HistoryRounded className="icon" />
          </ListItemIcon>
          <ListItemText>Activity Log</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem key="FileActivity" disablePadding selected={props.selected === 'fileActivity'}>
        <ListItemButton LinkComponent={Link} to={`/customer-account-file-activity?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>
          <ListItemIcon>
            <TextSnippetRounded className="icon" />
          </ListItemIcon>
          <ListItemText>Data Loads & Match Activity</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem key="Prescan" disablePadding selected={props.selected === 'prescan'}>
        <ListItemButton LinkComponent={Link} to={`/prescan?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>
          <ListItemIcon>
            <SettingsSuggestRounded className="icon" />
          </ListItemIcon>
          <ListItemText>List Configuration Prescan</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem key="Users" disablePadding selected={props.selected === 'users'}>
        <ListItemButton LinkComponent={Link} to={`/customer-account-users?id=${carrierId}&status=${carrierStatus}&name=${companyName}`}>
          <ListItemIcon>
            <ContactsRounded className="icon" />
          </ListItemIcon>
          <ListItemText>Users</ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  )

  return (
    <DrawerWrapper>
      <AppBar
        position="absolute"
        sx={{
          right: 'auto',
          left: 0,
          width: '95px',
          mt: '185px',
          pl: '24px',
          height: '50px',
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
          display: { md: 'none' },
        }}
      >
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, mt: 1, display: { md: 'none' }, fontSize: '15px' }}
          >
            MENU
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { xs: 0, md: drawerWidth }, flexShrink: { md: 0 }, mr: { xs: 0, md: 3 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '280px', mt: '60px' },
            '& .Mui-selected': {
              backgroundColor: 'primary.dark',
              color: '#fff'
            },
            '& .Mui-selected .MuiListItemButton-root:hover': {
              backgroundColor: 'transparent',
              color: '#fff'
            },
            '& .Mui-selected .icon': {
              color: '#fff'
            },
            '& .MuiListItem-root': {
              marginBottom: '10px',
              height: '50px'
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: 0, md: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </DrawerWrapper>

  );
}

SideNavigation.propTypes = {
  selected: PropTypes.string
}

export default SideNavigation;