import { FormHelperText } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import PropTypes from 'prop-types';


function FieldValidationMessage({ fieldTouched, validationMessage }) {
  return (fieldTouched && validationMessage && 
    <FormHelperText error sx={{ml: -1, fontWeight: 400}}>
      <InfoOutlined sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />
      {validationMessage}
    </FormHelperText>
  )
}

FieldValidationMessage.propTypes = {
  fieldTouched: PropTypes.bool,
  validationMessage: PropTypes.string
}

export default FieldValidationMessage;