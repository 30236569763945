import React from 'react';

import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import { RequiredAuth } from './RequiredAuth';
import CustomerAccounts from 'src/pages/CustomerAccounts';
import AddCustomerAccount from 'src/pages/AddCustomerAccount';
import CustomerAccountDetails from 'src/pages/Customer Account Details';
import CustomerAccountFileActivity from 'src/pages/Customer Account File Activity';
import CustomerAccountUsers from 'src/pages/Customer Account Users';
import CustomerAccountAddUser from 'src/pages/Customer Account Users/Customer Account Add User';
import CustomerAccountEditUser from 'src/pages/Customer Account Users/Customer Account Edit User';
import CustomerAccountSetup from 'src/pages/Customer Account Setup';
import Prescan from 'src/pages/Prescan';
import LoadingIcon from 'src/components/InitialLoader/loadingIcon';
import InitialLoader from 'src/components/InitialLoader';
import NotFound404 from 'src/pages/404';
import ErrorPage500 from 'src/pages/500';
import { OktaAuth } from './OktaAuth';
import { OktaTokens } from './OktaTokens';
import ActiveListSummary from 'src/pages/ActiveListSummary';
import ActivityLog from 'src/pages/ActivityLog';
import NotAuthorized401 from 'src/pages/401';
import OktaSecurity from './OktaSecurity';

const AppRoutes = () => {

  const router = createBrowserRouter(
    createRoutesFromElements (
      <Route element={<OktaSecurity />}>
        <Route element={<RequiredAuth />}>
          <Route element={<InitialLoader/>}>
            <Route element={<OktaAuth/> }>
              <Route path='/login/okta-callback' element={<OktaTokens/>} errorElement={NotAuthorized401}/>
              <Route path='/' element={<CustomerAccounts />} />
              <Route path='/add-customer-account' element={<AddCustomerAccount />} />
              <Route path='/active-list-summary' element={<ActiveListSummary />} />
              <Route path='/activity-log' element={<ActivityLog />} />
              <Route path='/customer-account-details' element={<CustomerAccountDetails />} />
              <Route path='/customer-account-setup' element={<CustomerAccountSetup />} />
              <Route path='/customer-account-file-activity' element={<CustomerAccountFileActivity />} />
              <Route path='/customer-account-users' element={<CustomerAccountUsers />} />
              <Route path='/customer-account-add-user' element={<CustomerAccountAddUser />} />
              <Route path='/customer-account-edit-user' element={<CustomerAccountEditUser />} />
              <Route path='/prescan' element={<Prescan />} />
            </Route>
          </Route>
        </Route>
        <Route path="/login/callback" element={<LoginCallback errorComponent={NotAuthorized401} loadingElement={<LoadingIcon />}/>} errorElement={NotAuthorized401}/>
        <Route path='*' element={<NotFound404 />} />
        <Route path='401' element={<NotAuthorized401 />} />
        <Route path='500' element={<ErrorPage500 />} />
      </Route>
    )
  );

  return(
    <RouterProvider router={router}/>
  );
}

export default AppRoutes;