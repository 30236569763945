import React from 'react';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';

function DisplayMoreDetailsMatchResults({ title, newMatches, deletedMatches, otherMatches }) {

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={3} sx={{ minWidth: '250px' }}>
          <Typography variant="subtitle2" color="text.primary">{newMatches} New Matches (Net New)</Typography>
          <Typography variant="caption">Number of customers who matched for the very first time.</Typography>
        </Grid>
        <Grid item xs={1}><Divider sx={{ height: '115%', mt: '-4px', ml: 4, width: '3px' }} orientation="vertical" variant="middle" display="flex" /></Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="text.primary">{otherMatches} Updated Matches</Typography>
          <Typography variant="caption">Number of customers who have an additional source match and/or an update to the death record they previously matched against.</Typography>
        </Grid>
        <Grid item xs={1}><Divider sx={{ height: '115%', mt: '-4px', ml: 4, width: '3px' }} orientation="vertical" variant="middle" display="flex" /></Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="text.primary">{deletedMatches} Deleted Matches</Typography>
          <Typography variant="caption">Number of customers who matched before but the death record provided now has a delete request and we're passing that on for your information.</Typography>
        </Grid>
      </Grid>
    </>
  )
}

DisplayMoreDetailsMatchResults.propTypes = {
  title: PropTypes.string.isRequired,
  newMatches: PropTypes.number,
  deletedMatches: PropTypes.number,
  otherMatches: PropTypes.number,
}

export default DisplayMoreDetailsMatchResults;
