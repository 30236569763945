import React from 'react';
import { Skeleton, Box } from '@mui/material';

const SkeletonRow = () => (
  <Box sx={{width: {xs: "200%", sm: '120%', md: '100%'} }}>
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "15.5%", float: 'left' }} /><Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, mr: 2, width: '15.5%', float: 'left' }} /> <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, mr: 2, width:'33%', float: 'left' }} /> <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, mr: 2, width: '24%', float: 'left' }} /> <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, width: "3%", float: 'left' }} />
  </Box>
);

function SkeletonRowUsers() {

  return (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  )
}

export default SkeletonRowUsers;